/* eslint-disable max-len */
export const productRequestConstants = {
  add: 'Add',
  addSalesPerson: 'Add Salesperson',
  address: 'Addresses',
  allowRequest: 'Allow Request',
  awaitingApproval: 'Awaiting Approval',
  back: 'Back',
  backToAllOrders: 'Back to all orders',
  cancel: 'Cancel',
  close: 'Close',
  closedOffer: 'Offer window closed',
  confirmationLastLook: 'Last look assigned successfully',
  confirmationMsg: 'Are you sure you want to delete this Sales Person?',
  confirmRequest: 'Are you sure you want to allow request.',
  confirmationRejectRequest :'Are you sure you want to reject request.',
  confirmLastLook: 'Last Look Confirmation',
  confirmMsgLastLook:'Are you sure you want to assign last look to this retailer, it cannot be changed later',
  contactEmail: 'Contact email',
  contactInfo: 'Contact Info',
  contactPhone: 'Contact phone',
  delete: 'Delete',
  deleteRetailerConfirmation: 'There was an error deleting the retailer',
  deleteSalesPerson:'Delete Sales Person',
  delivery: 'Delivery',
  deliveryAddress: 'Delivery address',
  details: 'Details',
  draft: 'Draft',
  email: 'Email',
  errorLastLook: 'There was an error confirming last look of the retailer',
  errorOccurred: 'An error has occurred',
  expireOn: 'Expires on',
  expirationDate: 'Expiration Date:',
  expirationMsg: 'You may extend the expiration window.',
  expirationUpdated: 'Updated Successfully',
  expiresIn: 'Expires in',
  extendedWindow: 'Extended Window',
  extendWindow: 'Extend Window',
  extendWindowMsg: 'Please select how long to extend the offer window for this product request',
  farmName: 'Farm name',
  farmer: 'Farmer',
  farmerReview: 'Farmer Review expires in',
  financing: 'Financing',
  fulfillment: 'Fulfillment',
  lastLook: 'Last Look',
  open: 'Open',
  paymentType: 'Payment type',
  phoneNo: 'Phone number',
  priceRequest: 'PRICE REQUEST',
  product: 'Product',
  pricingRequest: 'Order',
  rejectRequest: 'Reject Request',
  retailers: 'Retailers',
  salespersonDeletedSuccessfully: 'Sales Person successfully deleted',
  save: 'Save',
  submittedOn: 'Offer submitted',
  submit: 'Submit',
  successfully: 'Approved Successfully',
  total: 'Total',
  view: 'View',
  viewDetailHeading: 'You are viewing an offer from',
  viewOffer: 'View Offer',
};

export const dateExtention = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
];

export const confirmationText={
  extendWindow:'Please select how long to extend the offer window for this product request.',
  placeOffer:"Please select how long to extend the offer window for this product request.",
  offerClosed: "Please select how long to extend the offer window for this product request.",
  receivedOffer:"Please select how long to extend the offer window for this product request.",
};
