import { Client } from '@/utilities/api/Client';
import { FeaturedManufacturerEndpoint } from '@api/endpoints';
import qs from 'qs';

export class FeaturedManufacturerApi {
  static create (body: FeaturedManufacturerEndpoint.Create.Request) {
    return Client('featured-manufacturer', { body, method: 'POST' });
  }
  static list (
    search?: string,
  ): Promise<FeaturedManufacturerEndpoint.ListForAdmin.Response> {
    // TODO: Implement pagination
    const queryString = '?' + qs.stringify({ search, limit: 100 });
    return Client('admin/featured-manufacturer' + queryString);
  }

  static delete (id: string): Promise<void> {
    return Client(`featured-manufacturer/${id}`, { method: 'DELETE' });
  }

  static reOrderFeaturedManufacturer (
    ids: string[],
  ): Promise<FeaturedManufacturerEndpoint.ListForAdmin.Response[]> {
    return Client(`featured-manufacturer`, { method: 'PATCH', body: ids });
  }
}
