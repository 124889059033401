import { ReactComponent as ProductIcon } from '@/assets/icons/product-icon.svg';
import { Button, FullscreenDialog, VSpacer } from '@/components/DesignSystem';
import { ProductList } from '@/components/shared/ListSelector/ProductList';
import {
  ViewSelectedProductsModal,
} from '@/components/shared/ListSelector/ViewSelectedProductsModal';
import React, { useState } from 'react';

interface AddProductsDialogProps {
  close: () => void,
  onChange: (updatedIds: string[]) => void,
  selectedProductIds: string[],
}

export const AddProductsDialog = ({
  close,
  onChange,
  selectedProductIds = [],
}: AddProductsDialogProps) => {
  const [showViewAddedProductsModal, setShowViewAddedProductsModal] = useState(false);

  return (
    <>
      <FullscreenDialog
        actionButton={
          <Button
            onClick={() => {
              onChange(selectedProductIds);
              close();
            }}
            testID="action-button"
          >
            Done adding products
          </Button>
        }
        contentStyle={{ alignSelf: 'center', width: '100%' }}
        open
        testID="add-locations-dialog"
        title="Retailer Products"
        titleDescriptor={
          <Button
            onClick={() => setShowViewAddedProductsModal(true)}
            startIcon={<ProductIcon fill="#EDF354" />}
            testID="added-locations-button"
            variant="text"
          >
            Added products ({selectedProductIds.length})
          </Button>
        }
      >
        <VSpacer size="6" />
        <ProductList
          onChange={onChange}
          selectedIds={selectedProductIds}
        />
      </FullscreenDialog>
      {showViewAddedProductsModal && (
        <ViewSelectedProductsModal
          onChange={onChange}
          onClose={() => setShowViewAddedProductsModal(false)}
          productIds={selectedProductIds}
          testID="view-added-products-modal"
        />
      )}
    </>
  );
};
