import { Button, Chip, HSpacer, VSpacer } from '@/components/DesignSystem';
import { useGetRetailerById } from '@/hooks/useHierarchyOfRetailers';
import EditIcon from '@mui/icons-material/Edit';
import { Avatar, Card, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomTypography } from './CustomStyledComponents';
import AddRetailer from './RetailerFormDialog';
import { AppConfig } from '@/constants/AppConfig';
import Store from '@mui/icons-material/Store';

const RetailerDetailsCard = () => {
  const { id } = useParams();

  const [isEditRetailer, setIsEditRetailer] = useState(false);

  const editRetailer = () => {
    setIsEditRetailer(true);
  };

  const { retailer } = useGetRetailerById(id ?? '');

  const imageUrl = retailer?.image && `${AppConfig.staticImageHost}/${retailer.image}`;

  return (
    <Card sx={{ padding: '20px 20px 24px 20px' }}>
      <Stack direction="row">
        <Avatar
          alt={`${retailer?.name ?? 'retailer'} logo`}
          src={imageUrl ?? undefined}
          sx={{
            bgcolor: '#EBEBEB',
            height: 56,
            width: 56,
          }}
        >
          {imageUrl ? null : (
            <Store sx={{ height: 42, width: 42 }} />
          )}
        </Avatar>
        <HSpacer size="5" />
        <Stack direction="column" spacing={0.7} width="100%">
          <CustomTypography
            color={'#C9C6BE'}
            fontSize={'11px'}
            fontWeight={'500'}
            letterSpacing={'1.25px'}
            textTransform={'uppercase'}
          >
            ID# {retailer?.id}
          </CustomTypography>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
          >
            <CustomTypography
              fontSize='28px'
              letterSpacing={'0.5px'}
              lineHeight={'10px'}
              textTransform={'capitalize'}
            >
              {retailer?.name}
            </CustomTypography>
            <Button
              onClick={editRetailer}
              startIcon={<EditIcon />}
              testID='edit-details'
              variant='outlined'
            >
              Edit
            </Button>
          </Stack>
        </Stack>
      </Stack>
      {!!retailer?.isErpEnabled && (
        <>
          <VSpacer size="3" />
          <Chip color="info" label="ERP Imported" testID="erp-imported-chip" />
        </>
      )}
      {isEditRetailer && (
        <AddRetailer
          initialValue={retailer}
          isAddRetailerOpen={isEditRetailer}
          setIsAddRetailerOpen={setIsEditRetailer}
        />
      )}
    </Card>
  );
};

export default RetailerDetailsCard;
