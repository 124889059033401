import { Stack } from '@mui/material';
import { Button, HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { useState } from 'react';
import Add from '@mui/icons-material/Add';
import { ApiRewardsProgram } from '@api/interfaces/ApiRewardsProgram';
import {
  RewardsProgramModal,
} from '@/pages/Admin/HierarchyOfRetailers/Retailer/RewardsPrograms/RewardsProgramModal';
import { useMutation, useQueryClient } from 'react-query';
import { RewardsProgramEndpoint } from '@api/endpoints/RewardsProgramEndpoint';
import { RewardsProgramApi } from '@/utilities/api/RewardsProgramApi';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { QueryKeys } from '@/constants/QueryKeys';
import { formatDate } from './helpers';
import { getJsDateFromString } from '@shared/utilities';

interface RewardsProgramsCardProps {
  disabled: boolean,
  retailerId: string,
  rewardsProgram?: ApiRewardsProgram,
}

export const RewardsProgramsCard = ({
  disabled,
  retailerId,
  rewardsProgram,
}: RewardsProgramsCardProps) => {
  const { openSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [showRewardsProgramModal, setShowRewardsProgramModal] = useState(false);

  const startJsDate = rewardsProgram?.startDate
    ? getJsDateFromString(rewardsProgram.startDate)
    : undefined;
  const endJsDate = rewardsProgram?.endDate
    ? getJsDateFromString(rewardsProgram.endDate)
    : undefined;

  const { mutate: createProgram } = useMutation(
    (body: RewardsProgramEndpoint.Create.Request) => (
      RewardsProgramApi.create(body)
    ),
    {
      onSuccess: async () => {
        openSnackbar('Rewards program added');
        await queryClient.invalidateQueries(QueryKeys.GET_RETAILER_BY_ID);
      },
      onError: (error: { message: string }) => {
        openSnackbar(error.message || 'An error occurred while adding a rewards program');
      },
    },
  );

  const { mutate: updateProgram } = useMutation(
    (body: RewardsProgramEndpoint.Update.Request) => (
      RewardsProgramApi.update(rewardsProgram!.id, body)
    ),
    {
      onSuccess: async () => {
        openSnackbar('Rewards program updated');
        await queryClient.invalidateQueries(QueryKeys.GET_RETAILER_BY_ID);
      },
      onError: (error: { message: string }) => {
        openSnackbar(error.message || 'An error occurred while updating the rewards program');
      },
    },
  );

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Text category="body-xlarge">
            Rewards Program
          </Text>
          {!!rewardsProgram && (
            <>
              <VSpacer size="5" />
              <Text category="body-large">
                {rewardsProgram.name}
              </Text>
              <VSpacer size="2" />
              <Stack direction="row">
                <Text sx={{ color: '#D8D8D8' }}>
                  Created on :
                </Text>
                <HSpacer size="2" />
                <Text>
                  {formatDate(rewardsProgram.createdAt)}
                </Text>
                <HSpacer size="4" />
                <Text sx={{ color: '#D8D8D8' }}>
                  Current Program Period :
                </Text>
                <HSpacer size="2" />
                <Text>
                  {formatDate(startJsDate!)}
                  {' to '}
                  {formatDate(endJsDate!)}
                </Text>
              </Stack>
            </>
          )}
        </Stack>
        <Button
          color={rewardsProgram ? 'inherit' : 'primary'}
          disabled={disabled}
          onClick={() => setShowRewardsProgramModal(true)}
          startIcon={!rewardsProgram && <Add />}
          testID="rewards-program-button"
          variant="outlined"
        >
          {rewardsProgram ? 'View Program' : 'Add Program'}
        </Button>
      </Stack>
      {showRewardsProgramModal && (
        <RewardsProgramModal
          existingProgram={rewardsProgram}
          onClose={() => setShowRewardsProgramModal(false)}
          onCreate={(body) => createProgram({ ...body, retailerId })}
          onUpdate={updateProgram}
          retailerId={retailerId}
        />
      )}
    </>
  );
};
