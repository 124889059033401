
export function addContrastingBackground (img: HTMLImageElement): string {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('Failed to get canvas context');
  }

  canvas.width = img.width;
  canvas.height = img.height;

  ctx.drawImage(img, 0, 0);

  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const data = imageData.data;

  let r = 0, g = 0, b = 0;
  let pixelCount = 0;

  for (let i = 0; i < data.length; i += 4) {
    if (data[i + 3]) {
      r += data[i];
      g += data[i + 1];
      b += data[i + 2];
      pixelCount += 1;
    }
  }

  const avgR = r / pixelCount;
  const avgG = g / pixelCount;
  const avgB = b / pixelCount;

  const luminance = 0.299 * avgR + 0.587 * avgG + 0.114 * avgB;
  const backgroundColor = luminance < 128 ? 'white' : 'black';

  return getImageAsDataURL(img, backgroundColor, 'image/jpg');
}

export function getImageAsDataURL (
  img: HTMLImageElement,
  backgroundColor?: string,
  dataType?: string,
) {
  const newCanvas = document.createElement('canvas');
  const newCtx = newCanvas.getContext('2d');

  if (!newCtx) {
    throw new Error('Failed to get new canvas context');
  }

  newCanvas.width = img.width;
  newCanvas.height = img.height;

  if (backgroundColor) {
    newCtx.fillStyle = backgroundColor;
  }
  newCtx.fillRect(0, 0, newCanvas.width, newCanvas.height);
  newCtx.drawImage(img, 0, 0);

  return newCanvas.toDataURL(dataType);
}
