import React, { useRef, useState } from 'react';
import { Button as Button, Text } from '@/components/DesignSystem';
import UploadIcon from '@mui/icons-material/Upload';
import { Box, Stack, Typography } from '@mui/material';
import { ManufacturerApi } from '@/utilities/api/ManufacturerApi';
import { useMutation, useQueryClient } from 'react-query';
import { QueryKeys } from '@/constants/QueryKeys';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';

import { ToastMessages } from '@/constants/constant';
import { ApiManufacturer } from '@api/interfaces';
import ConfirmDialog from './ConfirmDialog';
import { sanitizedFileName } from '@shared/utilities';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { validateImage } from '@/pages/Admin/HierarchyOfRetailers/Locations/AddLocation';

const UploadImage = ({
  id,
  manufacturer,
}: {
  id: string;
  manufacturer: ApiManufacturer;
}) => {
  const [error, setError] = useState('');
  const [showConfirmRemoveLogoModal, setShowConfirmRemoveLogoModal] = useState(false);
  const { openSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    const imageFile = event.target.files?.[0];

    if (imageFile) {
      const error = validateImage(imageFile);
      if (error) {
        setError(error);
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;

        image.onload = () => {
          uploadLogo({ name: imageFile.name?.replace(/\s/g, ''), base64: reader.result });
        };
      };
      reader.readAsDataURL(imageFile);
    }
  };

  const getFileName = (nameString: string) => {
    const lastIndex = nameString.lastIndexOf('-');
    return nameString.substring(lastIndex + 1);
  };

  const { mutate: uploadLogo, isLoading } = useMutation(
    ({ name, base64 }: { name: string; base64: string | ArrayBuffer | null }) =>
      ManufacturerApi.update(id, {
        logo: `${sanitizedFileName(name)}+${base64}`,
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.GET_MANUFACTURERS);
        await queryClient.invalidateQueries(QueryKeys.GET_MANUFACTURER_BY_ID);
        openSnackbar(ToastMessages.adminRetailerUploadLogoSuccess);
      },
      onError: () => {
        openSnackbar(ToastMessages.adminRetailerUploadLogoFailure);
      },
    },
  );

  const { mutate: deleteLogo, isLoading: isRemoveLogoLoading } = useMutation(
    () => ManufacturerApi.update(id, { logo: manufacturer?.logo ?? '' }),
    {
      onSuccess: async () => {
        openSnackbar('Logo removed successfully');
        await queryClient.invalidateQueries(QueryKeys.GET_MANUFACTURERS);
        await queryClient.invalidateQueries(QueryKeys.GET_MANUFACTURER_BY_ID);
        setShowConfirmRemoveLogoModal(false);
      },
    },
  );

  return (
    <Stack
      alignItems='center'
      direction='row'
      justifyContent='space-between'
      spacing={3}
      sx={{ padding: '20px' }}
    >
      <Stack spacing={1}>
        <Text category='h6'>Logo</Text>
        {!!error?.length && <Typography color='error'>{error}</Typography>}
      </Stack>
      <Box>
        {!manufacturer?.logo ? (
          <Button
            loading={isLoading}
            onClick={() => fileInputRef.current?.click()}
            startIcon={<UploadIcon />}
            testID='upload-logo-button'
            variant='outlined'
          >
            Upload Logo
            <input
              accept='image/png'
              id='raised-button-file'
              onChange={(event) => handleImageChange(event)}
              ref={fileInputRef}
              style={{ display: 'none' }}
              type='file'
            />
          </Button>
        ) : (
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
          >
            <Typography sx={{ marginRight: '28px' }}>
              {getFileName(manufacturer?.logo ?? '')}
            </Typography>
            <Button
              onClick={() => {
                setShowConfirmRemoveLogoModal(true);
              }}
              startIcon={<DeleteOutlineIcon />}
              testID='delete-logo'
              variant='text'
            >
              Remove
            </Button>
          </Stack>
        )}
        {showConfirmRemoveLogoModal && (
          <ConfirmDialog
            cancelButtonText='No'
            confirmButtonText='Yes'
            isLoading={isRemoveLogoLoading}
            isOpen={showConfirmRemoveLogoModal}
            message = 'Are you sure you want to delete the logo?'
            onClose={() => setShowConfirmRemoveLogoModal(false)}
            onConfirm={() => deleteLogo()}
            title= 'Delete Logo?'
          />
        )}
      </Box>
    </Stack>
  );
};

export default UploadImage;