import {
  Button,
  Card,
  HSpacer,
  IconButton,
  InfoTooltip,
  Input,
  MenuItem,
  Modal,
  NumericInput,
  SegmentedButton,
  Select,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import {
  PreciousMetals,
  Segment,
  Tier,
} from '@/pages/Admin/HierarchyOfRetailers/Retailer/RewardsPrograms/RewardsProgramModal';
import { ApiCategory } from '@api/interfaces';
import Add from '@mui/icons-material/Add';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import {
  CardActionArea,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  useTheme,
} from '@mui/material';
import { SharedConfig } from '@shared/SharedConfig';
import { Modify } from '@shared/utilities/UtilityTypes';
import { round } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { RewardsType } from '@shared/enums/RewardsType';
import { RewardsUom } from '@shared/enums/RewardsUom';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import Check from '@mui/icons-material/Check';

const { rewardsMaxTiers, rewardsMaxRewardPercent } = SharedConfig.settings;

type EditableSubcategoryRewards = Record<
  string,
  { rewardsType?: RewardsType, rewardsValue?: number }
>;

type EditableSegment = Modify<
  Segment,
  {
    rewardsType?: RewardsType,
    rewardsValue?: number,
    subcategoryRewards?: EditableSubcategoryRewards,
  }
>;

type EditableTier = Modify<Tier,
    {
      minimumSegments?: number,
      segments: EditableSegment[],
    }
  > & {
    isDefault?: boolean,
  };

interface RewardsTiersModalProps {
  categories: ApiCategory[],
  isPreciousMetals: boolean,
  existingTiers?: Tier[],
  onClose: () => void,
  onSave: (tiers: Tier[]) => void,
}

export const RewardsTiersModal = ({
  categories,
  existingTiers,
  isPreciousMetals,
  onClose,
  onSave,
}: RewardsTiersModalProps) => {
  const parentCategories = categories.filter(({ parentId }) => !parentId);
  const defaultSegments: EditableSegment[] = useMemo(() => parentCategories.map((category) => {
    const subcategories = categories.filter(({ parentId }) => parentId === category.id);
    const subcategoryRewards = subcategories.length
      ? Object.fromEntries(subcategories.map((subcategory) => ([
        subcategory.id,
        { rewardsType: RewardsType.Percent, rewardsValue: undefined },
      ])))
      : undefined;

    return {
      categoryId: category.id,
      minimumHurdle: 0,
      rewardsType: subcategoryRewards ? undefined : RewardsType.Percent,
      subcategoryRewards,
      uom: RewardsUom.Dollars,
    };
  }), [categories, parentCategories]);

  const defaultTier: EditableTier = {
    isDefault: true,
    minimumDollars: 0,
    minimumSegments: undefined,
    name: '',
    segments: defaultSegments,
  };

  const preciousMetalTiers = Object.values(PreciousMetals).map((metal) => {
    const isDefault = metal === PreciousMetals.Bronze;
    return {
      ...defaultTier,
      isDefault,
      minimumSegments: isDefault ? 0 : undefined,
      name: metal,
    };
  });

  const defaultNewTiers = isPreciousMetals
    ? preciousMetalTiers : [{ ...defaultTier, minimumSegments: 0 }];

  let initialTiers: EditableTier[] = existingTiers?.map((tier) => ({
    ...tier,
    isDefault: false,
    segments: defaultSegments.map((segment) => {
      const existingSegment = tier.segments.find((tierSegment) => (
        tierSegment.categoryId === segment.categoryId
      ));
      const hasSubcategories = (
        !!existingSegment?.subcategoryRewards && !!segment.subcategoryRewards
      );
      const isPercent = existingSegment?.rewardsType === RewardsType.Percent;
      return {
        ...segment,
        minimumHurdle: existingSegment?.minimumHurdle ?? segment.minimumHurdle,
        rewardsType: existingSegment?.rewardsType ?? segment.rewardsType,
        rewardsValue: typeof existingSegment?.rewardsValue === 'number'
          && existingSegment.rewardsValue >= 0
          ? round(isPercent ? existingSegment.rewardsValue * 100 : existingSegment.rewardsValue, 4)
          : undefined,
        subcategoryRewards: hasSubcategories ? Object.fromEntries(
          Object.entries(segment.subcategoryRewards!).map(([categoryId, rewards]) => {
            const existingRewards = existingSegment?.subcategoryRewards?.[categoryId];
            const isPercent = existingRewards?.rewardsType === RewardsType.Percent;
            return [
              categoryId,
              {
                ...rewards,
                rewardsType: existingRewards?.rewardsType ?? rewards.rewardsType,
                rewardsValue: typeof existingRewards?.rewardsValue === 'number'
                  && existingRewards.rewardsValue >= 0
                  ? round(
                    isPercent ? existingRewards.rewardsValue * 100 : existingRewards.rewardsValue,
                    4,
                  )
                  : undefined,
              },
            ];
          })) : segment.subcategoryRewards,
        uom: existingSegment?.uom ?? segment.uom,
      };
    }),
  })) ?? [];
  if (isPreciousMetals) {
    initialTiers = initialTiers.filter(
      (tier) => (Object.values(PreciousMetals) as string[]).includes(tier.name),
    );
  }
  if (initialTiers.length === 0) {
    initialTiers = defaultNewTiers;
  }
  const [tiers, setTiers] = useState<EditableTier[]>(initialTiers);
  const [selectedTierIndex, setSelectedTierIndex] = useState<number>(0);

  const selectedTier = tiers.at(selectedTierIndex)!;

  let minimumSegmentsError: string = '';
  if (
    !!selectedTier.minimumSegments
    && selectedTier.minimumSegments > parentCategories.length
  ) {
    minimumSegmentsError = `Cannot exceed the total number of categories (${parentCategories.length})`;
  }
  if (selectedTier.minimumSegments !== undefined && tiers.some((tier, i) => (
    i !== selectedTierIndex
    && selectedTier.minimumSegments === tier.minimumSegments
  ))) {
    minimumSegmentsError = 'Cannot have the same minimum segments as another tier';
  }
  const isDuplicateName = tiers.some((tier, i) => (
    i !== selectedTierIndex && tier.name === selectedTier.name
  ));

  const areSegmentsValid = (segments: EditableSegment[]) => {
    return segments.every((segment) => {
      const areSubcategoriesValid = segment.subcategoryRewards
        ? Object.values(segment.subcategoryRewards).every((rewards) => (
          typeof rewards.rewardsValue === 'number'
          && rewards.rewardsValue >= 0
          && (
            rewards.rewardsType !== RewardsType.Percent
            || rewards.rewardsValue <= rewardsMaxRewardPercent * 100
          )
          && (segment.uom !== RewardsUom.Dollars || !!rewards.rewardsType)
        ))
        : true;

      const isRewardsValueValid = typeof segment.rewardsValue === 'number' &&
        segment.rewardsValue >= 0;

      return (
        !!segment.uom
        && areSubcategoriesValid
        && (segment.subcategoryRewards || (isRewardsValueValid && segment.rewardsType))
      );
    });
  };

  const isValid = tiers.every((tier) => (
    !!tier.name
    && tier.minimumSegments !== undefined
    && !minimumSegmentsError
    && areSegmentsValid(tier.segments)
  ));

  const handleAddTier = () => {
    let newTiers = [...tiers, { ...defaultTier, isDefault: false }];
    if (isPreciousMetals) {
      newTiers = [...preciousMetalTiers];
      newTiers.forEach((newTier, index) => {
        const existingMetalTier = tiers.find((tier) => tier.name === newTier.name);
        if (existingMetalTier) {
          newTiers[index] = { ...existingMetalTier };
        }
      });
    }
    setTiers(newTiers);
    if (!isPreciousMetals) {
      setSelectedTierIndex(tiers.length);
    }
  };

  const handleDeleteTier = (index: number) => {
    setTiers(tiers.filter((_, i) => i !== index));
    if (selectedTierIndex >= index) {
      setTiers(tiers.filter((_, i) => i !== index));
      setSelectedTierIndex(selectedTierIndex - 1);
    }
  };

  const handleUpdateSelectedTier = (updates: Partial<EditableTier>) => {
    const newTiers = [...tiers];
    newTiers[selectedTierIndex] = {
      ...newTiers[selectedTierIndex],
      ...updates,
    };
    setTiers(newTiers);
  };

  const handleChangeSegment = (updated: EditableSegment) => {
    const newSegments = selectedTier.segments.map((segment) => {
      if (segment.categoryId === updated.categoryId) {
        return updated;
      } else {
        return segment;
      }
    });
    handleUpdateSelectedTier({ segments: newSegments });
  };

  const handleSave = () => {
    const tiersToSave = tiers.map((tier) => ({
      ...tier,
      segments: tier.segments.map((segment) => {
        const isPercent = segment.rewardsType === RewardsType.Percent;
        const rewardsValue = typeof segment.rewardsValue === 'number'
          && segment.rewardsValue >= 0
          && (
            isPercent ? segment.rewardsValue / 100 : segment.rewardsValue
          );
        return ({
          ...segment,
          rewardsType: (segment.rewardsType && !segment.subcategoryRewards)
            ? segment.rewardsType
            : null,
          rewardsValue: (typeof segment.rewardsValue === 'number'
            && segment.rewardsValue >= 0
            && !segment.subcategoryRewards)
            ? rewardsValue
            : null,
          subcategoryRewards: segment.subcategoryRewards && Object.fromEntries(
            Object.entries(segment.subcategoryRewards)
              .map(([id, rewards]) => {
                const isPercent = rewards.rewardsType === RewardsType.Percent;
                const rewardsValue = rewards.rewardsValue && (
                  isPercent ? rewards.rewardsValue / 100 : rewards.rewardsValue
                );
                return [
                  id,
                  {
                    rewardsType: rewards.rewardsType ?? null,
                    rewardsValue: rewardsValue ?? null,
                  },
                ];
              }),
          ),
        });
      }),
    })) as Tier[];
    onSave(tiersToSave);
  };

  const TierSelector = (
    <Stack minWidth="380px">
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Text category="body-large">
          {tiers.length}/{rewardsMaxTiers} tiers
        </Text>
        <Button
          disabled={tiers.length >= rewardsMaxTiers}
          onClick={handleAddTier}
          startIcon={<Add />}
          testID="tiers-modal-add-tier-button"
          variant="text"
        >
          Add
        </Button>
      </Stack>
      <List sx={{ paddingX: '1px' }}>
        {tiers.map((tier, i) => (
          <>
            <ListItem
              key={i}
              onClick={() => setSelectedTierIndex(i)}
              secondaryAction={(tier.isDefault || tiers.length === 1) ? (
                <Text category="label-medium">
                  Default
                </Text>
              ) : (
                <IconButton
                  aria-label="delete"
                  onClick={(e) => {
                    handleDeleteTier(i);
                    e.stopPropagation();
                  }}
                  testID={`tiers-modal-delete-tier-button-${i}`}
                >
                  <DeleteOutline />
                </IconButton>
              )}
              sx={[
                {
                  bgcolor: '#212120',
                  borderRadius: '8px',
                  boxSizing: 'border-box',
                  height: '56px',
                },
                i === selectedTierIndex
                  ? { outline: '1px solid yellow' }
                  : { cursor: 'pointer' },
              ]}
            >
              <ListItemText
                primary={tier.name || 'Untitled'}
              />
            </ListItem>
            {i < tiers.length - 1 && (
              <VSpacer size="3" />
            )}
          </>
        ))}
      </List>
    </Stack>
  );

  const TierEditor = (
    <Card sx={{ maxHeight: 570, overflowY: 'auto', width: '100%' }} testID="tiers-modal-editor">
      <Stack direction="row">
        <Text fontSize="18px" fontWeight={500}>
          Details
        </Text>
        <HSpacer size="3" />
        <InfoTooltip>
          The 'Tier name' must be unique to this retailer rewards program.
          The 'Minimum Total Spend' will require a customer to spend the specified amount
          before they can access the rewards tier and begin earning loyalty points.
          The ‘Minimum Total Segments’ will require a customer to reach the category hurdle
          for this specified number of segments before they can access the rewards tier
          and begin earning loyalty points.
        </InfoTooltip>
      </Stack>
      <VSpacer size="5" />
      <Input
        disabled={isPreciousMetals}
        error={isDuplicateName}
        helperText={isDuplicateName && 'Tier name must be unique'}
        label="Tier Name"
        onChangeText={(name) => handleUpdateSelectedTier({ name })}
        testID="tiers-modal-name-input"
        value={selectedTier.name}
      />
      <VSpacer size="7" />
      <Stack direction="row">
        <NumericInput
          decimals={2}
          label="Minimum total spend ($)"
          minValue={0}
          onChangeNumber={(minimumDollars) => handleUpdateSelectedTier({ minimumDollars })}
          prefix="$"
          showFixedDecimals
          testID="tiers-modal-minimum-dollars-input"
          value={selectedTier.minimumDollars}
        />
        <HSpacer size="5" />
        <NumericInput
          decimals={0}
          error={!!minimumSegmentsError}
          helperText={minimumSegmentsError}
          label="Minimum total segments"
          minValue={0}
          onChangeNumber={(minimumSegments) => handleUpdateSelectedTier({ minimumSegments })}
          testID="tiers-modal-minimum-segments-input"
          value={selectedTier.minimumSegments}
        />
      </Stack>
      <VSpacer size="5" />
      <Divider />
      <VSpacer size="5" />
      <Stack direction="row">
        <Text fontSize="18px" fontWeight={500}>
          Segments
        </Text>
        <HSpacer size="3" />
        <InfoTooltip>
          Each of the retailer's product categories are listed below.
          The minimum hurdle and loyalty percentage must be set for each product category.
          The only exception is that a minimum hurdle value is not allowed to be entered
          for the default tier within the program
        </InfoTooltip>
      </Stack>
      <VSpacer size="5" />
      <Stack gap="16px">
        {selectedTier.segments.map((segment) => {
          const category = categories.find(({ id }) => id === segment.categoryId)!;
          const subcategories = categories.filter(({ parentId }) => parentId === category.id);
          return (
            <SegmentEditor
              category={category}
              key={category.id+selectedTier.name}
              onChangeSegment={handleChangeSegment}
              segment={segment}
              subcategories={subcategories}
              testID={`tiers-modal-segment-${category.id}`}
            />
          );
        })}
      </Stack>
    </Card>
  );

  return (
    <Modal
      acceptButton={(props) => (
        <Button
          {...props}
          disabled={!isValid}
          onClick={() => {
            onClose();
            handleSave();
          }}
          testID="tiers-modal-save-button"
        >
          Save
        </Button>
      )}
      cancelButton={(props) => (
        <Button
          {...props}
          color="inherit"
          onClick={onClose}
          testID="tiers-modal-cancel-button"
        >
          Cancel
        </Button>
      )}
      largeModal
      onClose={onClose}
      open
      testID="tiers-modal"
      title="Add/Edit Tiers"
    >
      <Text>
        Create the rewards tiers that will be offered to the customers
      </Text>
      <VSpacer size="7" />
      <Text>
        A minimum of 1 tier is required. All fields are required.
      </Text>
      <VSpacer size="7" />
      <Stack direction="row">
        {TierSelector}
        <HSpacer size="7" />
        {TierEditor}
      </Stack>
    </Modal>
  );
};

interface SegmentEditorProps {
  category: ApiCategory,
  onChangeSegment: (segment: EditableSegment) => void,
  segment: EditableSegment,
  subcategories: ApiCategory[],
  testID: string,
}

const SegmentEditor = ({
  category,
  onChangeSegment,
  segment,
  subcategories,
  testID,
}: SegmentEditorProps) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { minimumHurdle, uom } = segment;
  const isCategoryValid = (
    !!subcategories.length
    || (
      typeof segment.rewardsValue === 'number'
        && segment.rewardsValue >= 0
        && (segment.uom !== RewardsUom.Dollars || !!segment.rewardsType)
    )
  );
  const areSubcategoriesValid = (
    !segment.subcategoryRewards
    || Object.values(segment.subcategoryRewards!).every((rewards) => (
      rewards.rewardsValue !== undefined
      && (segment.uom !== RewardsUom.Dollars || !!rewards.rewardsType)
    ))
  );
  const isValid = (
    minimumHurdle !== undefined
    && !!uom
    && isCategoryValid
    && areSubcategoriesValid
  );

  const handleUpdate = (updates: Partial<EditableSegment>) => {
    onChangeSegment({ ...segment, ...updates });
  };

  const handleUpdateSubcategory = (
    categoryId: string,
    updates: { rewardsType?: RewardsType, rewardsValue?: number },
  ) => {
    const subcategoryRewards = {
      ...segment.subcategoryRewards!,
      [categoryId]: {
        ...segment.subcategoryRewards![categoryId],
        ...updates,
      },
    };
    handleUpdate({ subcategoryRewards });
  };

  return (
    <Card
      sx={{ backgroundColor: theme.palette.background.default, borderRadius: '8px' }}
      testID={testID}
    >
      <>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <CardActionArea
            disableRipple
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{ '.MuiCardActionArea-focusHighlight': { backgroundColor: 'transparent' } }}
          >
            <Stack alignItems="center" direction="row" onClick={() => setIsExpanded(!isExpanded)}>
              <IconButton testID={`${testID}-expand-button`}>
                {isExpanded ? <ArrowDropUp /> : <ArrowDropDown />}
              </IconButton>
              <HSpacer size="3" />
              <Text category="headline-small">
                {category.name}
              </Text>
              {isValid && (
                <>
                  <HSpacer size="3" />
                  <CheckCircleOutline
                    sx={{ color: theme.palette.success.main, height: '24px', width: '24px' }}
                  />
                </>
              )}
            </Stack>
          </CardActionArea>
          <Stack direction="row">
            <NumericInput
              decimals={uom === RewardsUom.Dollars ? 2 : 4}
              label="Minimum hurdle"
              minValue={0}
              onChangeNumber={(minimumHurdle) => handleUpdate({ minimumHurdle })}
              prefix={uom === RewardsUom.Dollars ? '$' : undefined}
              sx={{ width: 230 }}
              testID={`${testID}-minimum-hurdle`}
              value={minimumHurdle}
            />
            <HSpacer size="5" />
            <Select
              label="uom"
              onChangeValue={(uom) => {
                const updates: Partial<EditableSegment> = { uom: uom as RewardsUom };
                if (uom === RewardsUom.Dollars) {
                  if (segment.subcategoryRewards) {
                    updates.subcategoryRewards = Object.fromEntries(
                      Object.entries(segment.subcategoryRewards).map(([id, rewards]) => [
                        id,
                        { ...rewards, rewardsType: RewardsType.Dollars },
                      ]),
                    );
                  } else {
                    updates.rewardsType = RewardsType.Dollars;
                  }
                }
                handleUpdate(updates);
              }}
              testID={`${testID}-uom-select`}
              value={uom}
              width={120}
            >
              {Object.values(RewardsUom).map((uom) => (
                <MenuItem key={uom} testID={`${testID}-uom-select-item-${uom}`} value={uom}>
                  {uom}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Stack>
        {isExpanded && (
          <>
            <VSpacer size="7" />
            <Stack gap="16px">
              {subcategories.length > 0 ? (
                subcategories.map((subcategory) => (
                  <SegmentRewardsInput
                    category={subcategory}
                    key={subcategory.id}
                    onUpdate={(updates) => handleUpdateSubcategory(subcategory.id, updates)}
                    segment={segment}
                    testID={`${testID}-category-input-${subcategory.id}`}
                  />
                ))
              ) : (
                <SegmentRewardsInput
                  category={category}
                  onUpdate={(updates) => handleUpdate(updates)}
                  segment={segment}
                  testID={`${testID}-category-input-${category.id}`}
                />
              )}
            </Stack>
          </>
        )}
      </>
    </Card>
  );
};

interface SegmentRewardsInputProps {
  category: ApiCategory,
  onUpdate: (updates: { rewardsType?: RewardsType, rewardsValue?: number }) => void,
  segment: EditableSegment,
  testID: string,
}

const SegmentRewardsInput = ({
  category,
  onUpdate,
  segment,
  testID,
}: SegmentRewardsInputProps) => {
  const isSubcategory = !!category.parentId;
  const isFixed = segment.uom === RewardsUom.Dollars;
  const { rewardsType, rewardsValue } = isSubcategory
    ? segment.subcategoryRewards![category.id]
    : segment;
  useEffect(() => {
    if (!isFixed && rewardsType === RewardsType.Percent) {
      onUpdate({ rewardsType: RewardsType.Dollars });
    }
  }, [isFixed, onUpdate, rewardsType]);

  useEffect(() => {
    if (
      rewardsType === RewardsType.Percent
      && rewardsValue !== undefined
      && rewardsValue > (rewardsMaxRewardPercent * 100)
    ) {
      onUpdate({ rewardsValue: Math.min(rewardsValue, rewardsMaxRewardPercent * 100) });
    }
  }, [rewardsType, rewardsValue, onUpdate]);

  return (
    <Stack>
      {isSubcategory && (
        <>
          <Text category="title-medium">
            {category.name}
          </Text>
          <VSpacer size="5" />
        </>
      )}
      <Stack width="300px">
        <SegmentedButton
          color="primary"
          disabled
          fullWidth
          selectedIndex={isFixed ? 0 : 1}
        >
          <Button
            aria-selected={isFixed}
            key="Fixed"
            startIcon={isFixed && <Check sx={{ width: "17px", height: "17px" }} />}
            testID={`${testID}-fixed-button`}
          >
            Fixed
          </Button>
          <Button
            aria-selected={!isFixed}
            key="Proportional"
            startIcon={!isFixed && <Check sx={{ width: "17px", height: "17px" }} />}
            testID={`${testID}-proportional-button`}
          >
            Proportional
          </Button>
        </SegmentedButton>
      </Stack>
      <VSpacer size="6" />
      <Stack alignItems="center" direction="row" height="48px">
        <Text pt="14px">
          {isFixed
            ? 'For every $1.00 spent, customers will get'
            : `For every 1.000 ${segment.uom} purchased, customers will get`
          }
        </Text>
        {isFixed && (
          <>
            <HSpacer size="3" />
            <Select
              onChangeValue={(rewardsType) => (
                onUpdate({ rewardsType: rewardsType as RewardsType })
              )}
              sx={{ width: '68px' }}
              testID={`${testID}-category-rewards-type`}
              value={rewardsType}
              width={68}
            >
              <MenuItem key="%" testID={`${testID}-rewards-type-percent}`} value="%">
                %
              </MenuItem>
              <MenuItem key="$" testID={`${testID}-rewards-type-dollars}`} value="$">
                $
              </MenuItem>
            </Select>
          </>
        )}
        <HSpacer size="3" />
        <NumericInput
          decimals={(rewardsType === RewardsType.Dollars || !isFixed) ? 4 : 3}
          maxValue={(rewardsType === RewardsType.Percent && isFixed)
            ? rewardsMaxRewardPercent * 100
            : undefined}
          minValue={0}
          onChangeNumber={(value) => onUpdate({ rewardsValue: value })}
          prefix={!isFixed ? '$' : undefined}
          sx={{ height: '0px', width: '112px' }}
          testID={`${testID}-category-rewards-value`}
          value={rewardsValue}
          width="112px"
        />
        <HSpacer size="3" />
        <Text pt="14px">
          cash back in points
        </Text>
      </Stack>
    </Stack>
  );
};
