import { QueryUtil } from "../Query";
import { Client } from "./Client";
import { OrderTransactionEndpoint } from "@api/endpoints/OrderTransactionEndpoint";

export class OrderTransactionsApi {
  static list (
    query: OrderTransactionEndpoint.List.Query,
  ): Promise<OrderTransactionEndpoint.List.Response> {
    return Client(`orders?${QueryUtil.stringify(query)}`);
  }
}