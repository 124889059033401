import { Client } from '@/utilities/api/Client';
import { QueryUtil } from '@/utilities/Query';
import { LoyaltySkuEndpoint } from '@api/endpoints/LoyaltySkuEndpoint';

export class LoyaltySkuApi {
  static create (body: LoyaltySkuEndpoint.Create.Request) {
    return Client('loyalty-skus', { body, method: 'POST' });
  }

  static list (
    filters: LoyaltySkuEndpoint.List.Query,
  ): Promise<LoyaltySkuEndpoint.List.Response> {
    const query = QueryUtil.stringify(filters);
    return Client(`loyalty-skus?${query}`);
  }

  static update (id: string, body: LoyaltySkuEndpoint.Update.Request) {
    return Client(`loyalty-skus/${id}`, { body, method: 'PATCH' });
  }
}
