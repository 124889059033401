import { Button, HSpacer, ProgressLoader, Toolbar, VSpacer } from '@/components/DesignSystem';
import { DateRangeModal } from '@/components/shared/DateRangeModal';
import { FriendlyPricingRequestStatus } from '@/constants/FriendlyPricingRequestStatus';
import { QueryKeys } from '@/constants/QueryKeys';
import { useUser } from '@/hooks/useUser';
import { PricingRequestsApi } from '@/utilities/api/PricingRequestsApi';
import { downloadCsv } from '@/utilities/Export';
import { PricingRequestEndpoint } from '@api/endpoints';
import { ApiPricingRequest } from '@api/interfaces';
import { Download } from '@mui/icons-material';
import { Box, Pagination, Stack } from '@mui/material';
import { PricingRequestStatus, UserType } from '@shared/enums';
import { SharedConfig } from '@shared/SharedConfig';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import PricingRequestItem from './PricingRequestListItem';
import { useSearch } from "@/hooks/useSearch";
import { FilterSelections } from "@/components/DesignSystem/Toolbar/interfaces";

const PricingRequestList = ({ onError } : {
  onError: (error: string) => void,
}) => {
  const [showOrderPaymentsModal, setShowOrderPaymentsModal] = useState(false);

  const { debouncedSearch, setSearch } = useSearch();

  const [page, setPage] = useState(0);

  const [filterSelections, setFilterSelections] =
    useState<FilterSelections | undefined>(() => new Map());

  const farmerIds = Array.from(filterSelections?.get('userId') ?? []);
  const statuses = Array.from(filterSelections?.get('status') ?? []);

  useEffect(() => {
    setPage(0);
  }, [debouncedSearch, filterSelections]);

  const query : PricingRequestEndpoint.ListForAdmin.Query = {
    page,
    search: debouncedSearch,
    status: statuses.map(status => status as PricingRequestStatus),
    userId: farmerIds,
  };

  const navigate = useNavigate();
  const { data: pricingRequestResponse } = useQuery(
    [QueryKeys.GET_PRICING_REQUESTS_FOR_ADMIN, query],
    () => PricingRequestsApi.getPricingRequestsForAdmin(query),
    { onError },
  );

  const { users: farmers } = useUser({
    hasExternalId: false,
    limit: SharedConfig.maxPageLimit,
    userType: [UserType.Farmer],
  });

  const handleOnRedirect = (pricingRequest: ApiPricingRequest) => {
    navigate(`/admin/pricing-request/${pricingRequest.id}`, {
      state: { pricingRequest },
    });
  };

  const {
    mutateAsync: exportOrderPayments,
    isLoading: isCsvExporting,
  } = useMutation(
    async ({ startDate, endDate }: { startDate?: Date, endDate?: Date }) => {
      const response = await PricingRequestsApi.exportOrderPayments({ startDate, endDate });
      const dateString = new Date().toISOString().slice(0, 10); // Format: YYYY-MM-DD
      downloadCsv(response.csv, `order-payment-export-${dateString}`);
    },
    { onError },
  );

  const farmerFilterOptions = farmers?.data
    .filter(farmer => !!farmer.firstName?.trim() && !!farmer.lastName?.trim())
    .map(farmer => ({ id: farmer.id, label: `${farmer.firstName?.trim()} ${farmer.lastName?.trim()}` }));

  return (
    <>
      <Stack alignItems="flex-end" direction="row" justifyContent="space-between">
        <Box sx={{ minWidth: '600px', maxWidth: '900px' }}>
          <Toolbar
            filters={[
              {
                id: 'userId',
                label: 'Farmer',
                options: farmerFilterOptions ?? [],
                selectionMethod: 'single-select',
              },
              {
                id: 'status',
                label: 'Status',
                options: (
                  Object.values(PricingRequestStatus).map(
                    (value) => ({ id: value, label: FriendlyPricingRequestStatus[value] }),
                  )
                ),
                selectionMethod: 'single-select',
              },
            ]}
            onChange={({ search, selections }) => {
              setSearch(search ?? '');
              setFilterSelections(selections);
            }}
            testID="pricing-request-list-toolbar"
            totalItems={pricingRequestResponse?.total ?? 0}
            totalUnit="GROWERS Order"
          />
        </Box>
        <Box pb="16px">
          <Button
            onClick={() => setShowOrderPaymentsModal(true)}
            testID="pricing-request-list-export-button"
            variant="text"
          >
            Export order payments
            <HSpacer size="3" />
            {isCsvExporting ? (
              <>
                <HSpacer size="2" />
                <ProgressLoader size="20px" type="circular" />
              </>
            ) : (
              <Download />
            )}
          </Button>
        </Box>
      </Stack>
      <VSpacer size="3" />
      {pricingRequestResponse?.data.map((pricingRequest: ApiPricingRequest) => (
        <React.Fragment key={pricingRequest.id}>
          <PricingRequestItem
            onSelect={() => {
              handleOnRedirect(pricingRequest);
            }}
            pricingRequest={pricingRequest}
          />
          <VSpacer size="3" />
        </React.Fragment>
      ))}
      <Stack alignItems="center">
          <VSpacer size="9" />
          <Pagination
            count={(pricingRequestResponse?.lastPage ?? 0) + 1}
            onChange={
              (_, page) => setPage(page - 1 )
            }
            page={(pricingRequestResponse?.page ?? 0) + 1}
          />
      </Stack>
      {showOrderPaymentsModal && (
        <DateRangeModal
          acceptButtonLabel="Export"
          onClose={() => setShowOrderPaymentsModal(false)}
          onConfirm={async (startDate, endDate) => {
            setShowOrderPaymentsModal(false);
            await exportOrderPayments({ startDate, endDate });
          }}
          open
          subline="Choose the start and end dates for the report.
       These dates correspond to the date the order was paid."
          testID="export-order-payments-modal"
          title="Export Order Payments"
        />
      )}
    </>
  );
};


export default PricingRequestList;
