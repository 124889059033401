import { Card, HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { PromotionChip } from '@/components/shared/Promotion/PromotionChip';
import { ApiPromotion } from '@api/interfaces/ApiPromotion';
import { EditOutlined } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import { PromotionTargetUserType } from '@shared/enums/PromotionTargetUserType';
import React from 'react';

type ProductListData = {
  id: string,
  manufacturerName?: string,
  name: string
}

interface ProductListItemProps {
  editable?: boolean
  farmerPromotions: ApiPromotion[],
  getoneDataList: (id:string) => void,
  handleEdit: (id:string) => void,
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void,
  productListData: ProductListData,
  retailerPromotions: ApiPromotion[],
  setIsEditProductField: (isEdited:boolean) => void,
}

const ProductListItem = ({
  editable,
  farmerPromotions,
  getoneDataList,
  handleEdit,
  onClick,
  productListData,
  retailerPromotions,
  setIsEditProductField,
}: ProductListItemProps) => {
  return (
    <>
      <Card
        onClick={(e) => {
          onClick(e);
          getoneDataList(productListData?.id);
        }}
        sx={{ cursor: 'pointer' }}
        testID='product-list-item'
      >
        <Stack alignItems='center' direction='row' justifyContent='space-between'>
          <Stack>
            <Text category='title-medium' testID='product-name' >
              {productListData.name}
            </Text>
            <Text
              category='body-medium'
              sx={{ color:'#C9C6BE' }}
              testID='product-manufacturer-name'
            >
              {productListData.manufacturerName}
            </Text>
            <VSpacer size='4' />
            {!!(farmerPromotions.length || retailerPromotions.length) && (
              <>
                <Stack direction='row'>
                  {!!farmerPromotions.length && (
                    <>
                      <PromotionChip
                        promotions={farmerPromotions}
                        targetUserType={PromotionTargetUserType.Farmer}
                      />
                      <HSpacer size='4' />
                    </>
                  )}
                  {!!retailerPromotions.length && (
                    <PromotionChip
                      promotions={retailerPromotions}
                      targetUserType={PromotionTargetUserType.Retailer}
                    />
                  )}
                </Stack>
              </>
            )}
          </Stack>
          <Stack direction='row'>
            <Text
              alignContent='center'
              category='body-medium'
              sx={{ color:'#C9C6BE' }}
              testID='product-name'
            >
              {productListData.id}
            </Text>
            { editable && (
              <>
                <HSpacer size='5' />
                <Box>
                  <IconButton
                    data-testid='Edit'
                    onClick={(e) => {
                      e.stopPropagation();
                      getoneDataList(productListData?.id);
                      handleEdit(productListData?.id);
                      setIsEditProductField(true);
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                </Box>
              </>
            )}
          </Stack>
        </Stack>
      </Card>
    </>
  );
};
export default ProductListItem;
