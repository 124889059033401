import { LocalStorageUtility } from '@/utilities/LocalStorageUtility';
import { PricingRequestCartEndpoint } from '@api/endpoints';
import { ApiPricingRequestCart } from '@api/interfaces';
import { generateRandomToken } from '@shared/utilities';

export class ShoppingCartUtils {
  static CartKey = 'cart';
  static CartEventKey = 'cart-storage';

  static addShoppingCartItem (
    itemToAdd: ApiPricingRequestCart,
  ) {
    const shoppingCart = this.getShoppingCart();
    if (!itemToAdd.id) {
      itemToAdd.id = generateRandomToken();
    }
    const filtered = shoppingCart.filter((item) => item.id !== itemToAdd.id);
    const newCart = [...filtered, itemToAdd];
    this.setShoppingCart(newCart);
    return newCart;
  }

  static getShoppingCart (): ApiPricingRequestCart[] {
    return JSON.parse(LocalStorageUtility.getItem(this.CartKey) ?? '[]');
  }

  static clearCart () {
    this.setShoppingCart([]);
  }

  static removeShoppingCartItem (id: string) {
    const shoppingCart = this.getShoppingCart();
    const filtered = shoppingCart.filter((item) => item.id !== id);
    this.setShoppingCart(filtered);
    return filtered;
  }

  static setShoppingCart (cart: ApiPricingRequestCart[]) {
    LocalStorageUtility.set(this.CartKey, cart);
    window.dispatchEvent(new Event(this.CartEventKey));
  }

  static updateCartItem (
    id: string,
    updateData: PricingRequestCartEndpoint.UpdatePricingRequestCart.Request,
  ) {
    const cart = this.getShoppingCart();
    const cartItem = cart.find((item) => item.id === id);
    if (cartItem) {
      const updatedItem = { ...cartItem, ...updateData };
      this.addShoppingCartItem(updatedItem);
    }
  }
}
