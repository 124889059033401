import { AppConfig } from '@/constants/AppConfig';
import { themes } from '@/themes/MUITheme/MaterialUITheme';
import { LocalStorageUtility } from '@/utilities/LocalStorageUtility';

export const getTheme = () => {
  const subdomain = getSubdomain();
  if (!subdomain || !themes[subdomain]) {
    return themes['marketplace'];
  }
  return themes[subdomain];
};

export const getSubdomain = () => {
  if (AppConfig.env.local) {
    const subdomain = LocalStorageUtility.getItem('storefront');
    return subdomain ?? null;
  } else if (AppConfig.env.devel) {
    const pattern = /^https:?\/\/(.*).storefront.devel.growers.engineering(.*)$/;
    const match = window.location.href.match(pattern);
    return match?.[1];
  } else if (AppConfig.env.staging) {
    const pattern = /^https:?\/\/(.*).storefront.staging.growers.engineering(.*)$/;
    const match = window.location.href.match(pattern);
    return match?.[1];
  } else if (AppConfig.env.prod) {
    const pattern = /^https:?\/\/(.*).storefront.growers.ag(.*)$/;
    const match = window.location.href.match(pattern);
    return match?.[1];
  }
  return null;
};
