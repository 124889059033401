import { VSpacer } from '@/components/DesignSystem';
import { ApiRetailer } from '@api/interfaces';
import { Alert, Container, Divider, Stack } from '@mui/material';
import React, { useState } from 'react';
import ERPRetailerSelector from "@/components/shared/ListSelector/ERPRetailerSelector";
import PricingRequestList from "@/pages/Admin/PricingRequestList/PricingRequestList";
import OrderTransactionList from "@/pages/Admin/PricingRequestList/OrderTransactionList";

const OrdersOverview = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedRetailer, setSelectedRetailer] = useState<ApiRetailer | undefined>();

  const onRetailerSelected = (retailer?: ApiRetailer) => {
    if (retailer?.id !== selectedRetailer?.id) {
      setSelectedRetailer(retailer);
    }
  };

  return (
    <Container maxWidth="lg">
      <VSpacer size="8" />
      <Stack>
        <ERPRetailerSelector
          defaultMenuItem={{
            label: 'GROWERS',
            logo: 'growers',
          }}
          hasErpProducts={true}
          header='Orders'
          isErpEnabled={true}
          onSelect={onRetailerSelected}
        />
        <VSpacer size="6" />
        <Divider />
        <VSpacer size="6" />
        {!!errorMessage && (
          <Alert color="error" icon={false}>{errorMessage}</Alert>
        )}
        {selectedRetailer
          ? <OrderTransactionList onError={setErrorMessage} selectedRetailer={selectedRetailer} />
          : <PricingRequestList onError={setErrorMessage} />
        }
        <VSpacer size='14' />
      </Stack>
    </Container>
  );
};


export default OrdersOverview;