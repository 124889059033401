import { Button, HSpacer, Search, Text, VSpacer } from '@/components/DesignSystem';
import { debounce } from '@/utilities/debounce';
import Clear from '@mui/icons-material/Clear';
import { Chip, Stack } from '@mui/material';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import { FilterMenu, FilterOption } from '../FilterMenu/FilterMenu';

export interface FilterProps<T extends FilterDefault> {
  appliedFiltersLabel?: string;
  clearAllLabel?: string;
  defaultOpen?: boolean;
  filters: Filter[];
  onChange?: (result: T) => void;
  recordName?: string;
  testID: string;
  totalResults?: number;
}

export interface FilterDefault {
  search?: string;
}

export interface Filter {
  id: string;
  label: string;
  options: FilterOption[];
  singleSelect?: boolean;
}

export const Filter = <T extends FilterDefault>({
  appliedFiltersLabel = 'Applied filters',
  clearAllLabel = 'Clear all',
  defaultOpen = false,
  filters = [],
  onChange,
  recordName = 'result',
  testID,
  totalResults,
}: FilterProps<T>) => {
  const [selections, setSelections] = useState<Map<string, Set<FilterOption>>>(
    new Map(),
  );
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  useEffect(() => debounce(() => setDebouncedSearch(search), 600), [search]);

  useEffect(() => {
    onChange?.(getFilterResult(search, selections));
  }, [debouncedSearch, selections]);

  const clearAll = () => {
    setSelections(new Map());
  };

  const getFilter = (filterId: string) => {
    return filters.find((filter) => filter.id === filterId);
  };

  const getFilterChips = (testID: string) => {
    const chips: ReactNode[] = [];
    Array.from(selections.entries(), ([filterId, options]) => {
      options.forEach((option) => {
        chips.push(
          <Fragment key={option.id}>
            <Chip
              data-testid={`${testID}-filterchip-${option.label}`}
              deleteIcon={<Clear />}
              label={option.label}
              onDelete={() => removeFilterOption(filterId, option)}
            />
            <HSpacer size='5' />
          </Fragment>,
        );
      });
    });
    return chips;
  };

  const getFilterResult = (
    search: string,
    selections: Map<string, Set<FilterOption>>,
  ) => {
    const result = {} as any;
    if (search !== '') {
      result.search = search.toLowerCase();
    }
    Array.from(selections.entries(), ([filterId, options]) => {
      if (options.size) {
        const filter = getFilter(filterId);
        if (filter?.singleSelect) {
          result[filterId] = Array.from(options)[0]?.id;
        } else {
          result[filterId] = Array.from(options).map((option) => option.id);
        }
      }
    });
    return result;
  };

  const hasFilterSelections = () => {
    if (selections.size === 0) {
      return false;
    }
    const filterValues = Array.from(selections.values());
    return filterValues.some((values) => values.size > 0);
  };

  const removeFilterOption = (filterId: string, option: FilterOption) => {
    const filterValues = selections.get(filterId);
    if (filterValues) {
      filterValues.delete(option);
      setSelections(new Map(selections));
    }
  };

  const updateSelections = (
    key: string,
    value: FilterOption,
    isSelected: boolean,
  ) => {
    const selectionValues = selections.get(key);
    if (isSelected) {
      const filter = getFilter(key);
      if (selectionValues && !filter?.singleSelect) {
        selectionValues.add(value);
      } else {
        selections.set(key, new Set([value]));
      }
    } else {
      if (selectionValues) {
        const optionToRemove = Array.from(selectionValues).find(
          (option) => option.id === value.id,
        );
        if (optionToRemove) {
          selectionValues.delete(optionToRemove);
        }
      }
    }
    setSelections(new Map(selections));
  };

  return (
    <Stack>
      <Search
        onChangeText={setSearch}
        testID={`${testID}-search`}
        value={search}
        width={289}
      />
      {filters.length > 0 && (
        <>
          <VSpacer size='8' />
          <Stack alignItems='center' direction='row'>
            {filters.map((filter) => (
              <Fragment key={filter.id}>
                <FilterMenu
                  defaultOpen={defaultOpen}
                  label={filter.label}
                  onSelect={(option: FilterOption, selected: boolean) =>
                    updateSelections(filter.id, option, selected)
                  }
                  options={filter.options}
                  selectedOptions={selections.get(filter.id)}
                  singleSelect={filter.singleSelect}
                  testID={`${testID}-filtermenu`}
                />
                <HSpacer size='5' />
              </Fragment>
            ))}
          </Stack>
        </>
      )}
      {hasFilterSelections() && (
        <>
          <VSpacer size='5' />
          <Stack alignItems='center' direction='row'>
            <Text category='c1'>
              {appliedFiltersLabel}:
            </Text>
            <HSpacer size='5' />
            {getFilterChips(testID)}
            <Button
              onClick={clearAll}
              testID={`${testID}-clearall`}
              variant='text'
            >
              {clearAllLabel}
            </Button>
          </Stack>
        </>
      )}
      {totalResults !== undefined && (
        <>
          <VSpacer size='8' />
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
          >
            <Text
              category='p2'
              style={{ marginBottom: '10px' }}
              testID={`${testID}-totalresults`}
            >
              {totalResults} {recordName}
              {totalResults === 1 ? '' : 's'}
            </Text>
          </Stack>
        </>
      )}
    </Stack>
  );
};
