import { Button, Checkbox, Chip, HSpacer, Search, Text, VSpacer } from '@/components/DesignSystem';
import { stepperConstant } from '@/constants/productConstant';
import { QueryKeys } from '@/constants/QueryKeys';
import { useCategoryList } from '@/hooks/useProductQuery';
import { useSearch } from '@/hooks/useSearch';
import { ProductApi } from '@/utilities/api/ProductApi';
import { ApiCategory, ApiProduct } from '@api/interfaces';
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import {
  ArrowBack,
  ArrowForward as ArrowRight,
  Check,
  DeleteOutline,
  DragIndicator,
} from '@mui/icons-material';
import {
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Pagination,
  Stack,
  Step,
  Stepper,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { FilterType, ProductDetailsType, SetRankModalProps } from './Interfaces';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';

const styles: SXStyles = {

  cardPosition: {
    display: 'flex',
    alignItems: 'center',
    background: 'red',
    height: '44px',
    justifyContent: 'center',
    width: '72px',
  },

  keywordChip: {
    fontWeight: '600',
    marginBottom: '10px',
    marginRight: '10px',
  },
  
  listContainer: {
    borderBottom: '1px solid',
  },

  listItemText: {
    display: 'inline-block',
    marginRight: '15px',
  },

  listOfcompanion: {
    display: 'flex',
    borderTop: '1px solid',
    padding: '20px',
  },

  ratingCard: {
    display: 'flex',
    alignItems: 'center',
    background: '#262625',
    padding: '20px',
    margin: '10px 0',
    justifyContent: 'space-between',
  },

  searchResultText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  
  stepFirstHeading: {
    width: '50%',
    paddingBottom: '20px',
    '&.Mui-completed:first-child': {
      color: '#e4ff5b',
      borderBottom: '2px solid #e4ff5b',
    },
  },
  
  stepSecondHeading: {
    width: '50%',
    paddingBottom: '20px',
    '&.Mui-completed:last-child': {
      color: '#e4ff5b',
      borderBottom: '3px solid #e4ff5b',
    },
  },

  stepWrp: {
    textAlign: 'center',
    borderBottom: '1px solid #353530',
    marginRight: '5px',
  },
} as const;

const StepperModal = ({
  close,
  handleComplete,
  title,
  selectedStepperFieldData,
  selectedStepperRankText,
  searchCountText,
  isEdit = false,
}: SetRankModalProps) => {

  const steps = ['Add new products', 'Product ranking'];
  const [activeStep, setActiveStep] = useState(1);
  const [selectedData, setSelectedData] = useState<ProductDetailsType[]>([]);
  const { search, setSearch, debouncedSearch } = useSearch();
  const { categoryList } = useCategoryList();

  const selectedProducts = () => {
    if (isEdit && Array.isArray(selectedStepperFieldData)) {
      return selectedStepperFieldData.map((item) => {
        if ('productDetails' in item) {
          return item.productDetails;
        }
      });
    }
    return selectedStepperFieldData;
  };
  

  const [selectedProductsLists, setSelectedProductsLists] = useState<ProductDetailsType[]>(() => {
    const products = selectedProducts();
    if (Array.isArray(products)) {
      return products as ProductDetailsType[];
    } else {
      return [];
    }
  });
  const [selectedFilters, setSelectedFilters] = useState<FilterType>();
  const handleNext = () => {
    handleClear();
    setSearch('');
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    const selectedProductsWithRank = selectedProductsLists.map(
      (item: ProductDetailsType, index: number) => {
        return {
          ...item,
          rank: index + 1,
        };
      },
    );
    setSelectedData(selectedProductsWithRank);
  };

  const { data } = useQuery(
    [QueryKeys.GET_PRODUCT, debouncedSearch, selectedFilters],
    () =>
      ProductApi.productListData({
        ...selectedFilters,
        search: debouncedSearch,
      }),
  );

  const handleToggle = (product: ApiProduct) => {
    setSelectedProductsLists((previous: ProductDetailsType[]) => {
      const isSelected = previous.some(
        (previouslySelectedProduct: ProductDetailsType) =>
          previouslySelectedProduct?.id === product?.id,
      );
      if (isSelected) {
        return previous.filter(
          (previouslySelectedProduct: ProductDetailsType) =>
            previouslySelectedProduct?.id !== product?.id,
        );
      }
      return [...previous, product] as ProductDetailsType[];
    });
  };

  const handleDeleteRank = (id: string) => {
    const data = selectedData.filter((item: { id: string }) => item.id !== id);
    setSelectedProductsLists(data);
    setSelectedData(data);
  };

  const [calegoryData, setCategoryData] = useState(categoryList);
  const handleShowSelected = (id: string) => {
    const newData = categoryList?.map((item) => {
      if (id === item.id) {
        return { ...item, selectedField: true };
      } else {
        return {
          ...item,
          selectedField: false,
        };
      }
    });
    setCategoryData(newData);
  };
  const handleClear = () => {
    const newData = categoryList?.map((item) => {
      return {
        ...item,
        selectedField: false,
      };
    });
    setCategoryData(newData);
    if (debouncedSearch?.length) {
      setSelectedFilters({
        search: debouncedSearch,
      });
    }
    setSelectedFilters({ search: '' });
  };

  const reorder = (list: ProductDetailsType[], startIndex: number, endIndex: number) => {
    const result = list;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      selectedProductsLists,
      result.source.index,
      result.destination.index,
    );
    const rankedData = items.map((item: ProductDetailsType, index: number) => ({
      ...item,
      rank: index + 1,
    }));
    setSelectedData(rankedData);
    setSelectedProductsLists(items);
  };

  function isSubset (arr1: ProductDetailsType[], arr2: ApiProduct[] | undefined) {
    return arr2?.length && 
    arr2.every(({ id }) => arr1.some((item1) => item1.id === id));
  }

  const onSelectAllClick = () => {
    const isSelectedAll = isSubset(selectedProductsLists, data?.data);
    if (isSelectedAll) {
      return setSelectedProductsLists((previous) => {
        const remainingData = previous.filter(
          (apiItem) =>
            !data?.data?.some(
              (selectedItem) => selectedItem?.id === apiItem?.id,
            ),
        );
        return [...remainingData];
      });
    }
    setSelectedProductsLists((previous) => {
      const notSelectedData = data?.data?.filter(
        (apiItem) =>
          !previous?.some(
            (selectedItem) => selectedItem?.id === apiItem?.id,
          ),
      ) ?? [];
      return [...previous, ...notSelectedData] as ProductDetailsType[];
    });
  };


  useEffect(() => {
    if (debouncedSearch?.length) {
      setSelectedFilters((previousFilters) => ({ ...previousFilters, page: 0 }));
    }
  }, [debouncedSearch]);

  return (
    <Dialog maxWidth='xl' onClose={close} open={true} scroll='body'>
      <DialogContent>
        <DragDropContext onDragEnd={onDragEnd}>
          <Stack>
            <VSpacer size='4' />
            <Text category='h4'>{title}</Text>
            <VSpacer size='8' />
            <Stepper activeStep={activeStep} sx={styles.stepWrp}>
              {steps.map((label) => {
                return (
                  <Step
                    key={label}
                    sx={
                      activeStep === steps.length - 1
                        ? styles.stepFirstHeading
                        : styles.stepSecondHeading
                    }
                  >
                    {label}
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <Stack style={{ width: '730px' }}>
                <VSpacer size='8' />
                <Box sx={styles.searchResultText}>
                  <Text>
                    {`${
                      selectedProductsLists && selectedProductsLists.length
                    } ${selectedStepperRankText}`}
                  </Text>
                </Box>
                <div className='scrollable-container'>
                  <Droppable droppableId='droppable'>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {selectedProductsLists &&
                          selectedProductsLists?.map(
                            (item: { id: string; name: string }, i: number) => (
                              <Draggable
                                draggableId={JSON.stringify(item?.id)}
                                index={i}
                                key={item?.id}
                              >
                                {(provided, snapshot) => (
                                  <Card
                                    key={item?.id}
                                    ref={provided.innerRef}
                                    sx={{
                                      ...styles.ratingCard,
                                      backgroundColor: snapshot.isDragging
                                        ? '#101010ff'
                                        : '#262625ff',
                                    }}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <DragIndicator />
                                      <HSpacer size='5' />
                                      <Box sx={styles.cardPosition}>
                                        {i + 1}
                                      </Box>
                                      <HSpacer size='8' />
                                      <Text>{item.name}</Text>
                                    </Box>
                                    <Box sx={{ display: 'flex' }}>
                                      <IconButton>
                                        <DeleteOutline
                                          onClick={() =>
                                            handleDeleteRank(item.id)
                                          }
                                        />
                                      </IconButton>
                                    </Box>
                                  </Card>
                                )}
                              </Draggable>
                            ),
                          )}
                        {provided?.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </Stack>
            ) : (
              <>
                <VSpacer size='8' />
                <Search
                  onChangeText={setSearch}
                  testID='search-demo'
                  value={search}
                  width={730}
                />
                <VSpacer size='6' />
                <Box>
                  {calegoryData &&
                    calegoryData?.map((item: ApiCategory) => {
                      return (
                        <>
                          <Chip
                            icon={
                            item?.selectedField
                              ? <Check color={item?.selectedField ? 'primary' : undefined} />
                              : undefined
                            }
                            key={item.id}
                            label={item.name}
                            onClick={() => {
                              setSelectedFilters({
                                ...selectedFilters,
                                categoryId: [item.id],
                              });
                              handleShowSelected(item.id);
                            }}
                            testID={item.id}
                            variant={item.selectedField ? 'filled' : 'outlined'}
                          />
                          <HSpacer size='4' />
                        </>
                      );
                    })}
                  <Button
                    onClick={handleClear}
                    testID='clear-btn'
                    variant='text'
                  >
                    Clear All
                  </Button>
                  <HSpacer size='4' />
                </Box>
                <VSpacer size='6' />
                <Box sx={styles.searchResultText}>
                  <Text>{`${
                    data?.total
                  } ${searchCountText}`}</Text>
                  <Button
                    onClick={onSelectAllClick}
                    testID='button-demo-neutral-text'
                    variant='text'
                  >
                    {stepperConstant.selectAll}
                  </Button>
                </Box>
                <VSpacer size='6' />
                <Box sx={styles.listContainer}>
                  {data &&
                    data?.data?.map(
                      (item) => {
                        return (
                          <Box
                            key={item.id}
                            onClick={() => {
                              handleToggle(item);
                            }}
                            sx={styles.listOfcompanion}
                          >
                            <Checkbox
                              checked={selectedProductsLists?.some(
                                (previouslySelectedProduct) =>
                                  previouslySelectedProduct?.id === item?.id,
                              )}
                              testID={item.id}
                            >
                              <Text>{item.name}</Text>
                            </Checkbox>
                          </Box>
                        );
                      },
                    )}
                </Box>
              </>
            )}
          </Stack>
        </DragDropContext>
        {activeStep === 1 && data && data.lastPage !== 0 && (
          <Stack alignItems='center'>
            <VSpacer size='9' />
            <Pagination
              count={data.lastPage + 1}
              onChange={(event, page) =>
                setSelectedFilters({ ...selectedFilters, page: page - 1 })
              }
              page={data.page + 1}
            />
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={close}
          testID='demo-action-button'
          variant='text'
        >
          Cancel
        </Button>
        {activeStep === 2 && (
          <Button
            onClick={() => {
              setActiveStep((prevActiveStep) => prevActiveStep - 1);
            }}
            startIcon={<ArrowBack />}
            testID='back-button'
            variant='outlined'
          >
            Back
          </Button>
        )}
        <Button
          disabled={activeStep === steps.length - 1 && !selectedProductsLists?.length}
          endIcon={
            activeStep === steps.length - 1 ? (
              <ArrowRight fontSize='small' />
            ) : (
              ''
            )
          }
          onClick={
            activeStep === steps.length - 1
              ? handleNext
              : () => {
                handleComplete(selectedData);
              }
          }
          testID='next'
        >
          {activeStep === steps.length - 1
            ? stepperConstant.next
            : stepperConstant.finish}
        </Button>
      </DialogActions>
      <VSpacer size='8' />
    </Dialog>
  );
};

export default StepperModal;
