import { Client } from '@/utilities/api/Client';
import { RewardsProgramEndpoint } from '@api/endpoints/RewardsProgramEndpoint';

export class RewardsProgramApi {
  static create (
    body: RewardsProgramEndpoint.Create.Request,
  ): Promise<RewardsProgramEndpoint.Create.Response> {
    return Client(`rewards-programs`, {
      method: 'POST',
      body,
    });
  }

  static delete (id: string): Promise<void> {
    return Client(`rewards-programs/${id}`, { method: 'DELETE' });
  }

  static getActiveProgramForLocation (
    locationId: string,
  ): Promise<RewardsProgramEndpoint.GetActiveProgramForLocation.Response> {
    return Client(`rewards-programs/active-for-location/${locationId}`);
  }

  static update (
    id: string,
    body: RewardsProgramEndpoint.Update.Request,
  ): Promise<RewardsProgramEndpoint.Update.Response> {
    return Client(`rewards-programs/${id}`, {
      method: 'PATCH',
      body,
    });
  }
}
