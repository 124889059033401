import { Button, HSpacer, InlineMessage, Text, VSpacer } from '@/components/DesignSystem';
import { DateTime } from 'luxon';
import { Box, Card, Container, Divider, Stack, Tooltip } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { QueryKeys } from '@/constants/QueryKeys';
import { PricingRequestsApi } from '@/utilities/api/PricingRequestsApi';
import { productRequestConstants } from '@/constants/ProductRequest';
import { useQuery } from 'react-query';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import React from 'react';
import { SwapHorizontalCircle } from '@mui/icons-material';

const TotalCostComponent = ({
  staticString,
  price,
  fontSize = '16px',
}: {
  staticString: string;
  price: string;
  fontSize?: string;
}) => (
  <Stack display={'flex'} flexDirection={'row'} justifyContent='space-between'>
    <Text fontSize={fontSize} testID={`${staticString}-card`}>
      {staticString}
    </Text>
    <Text fontSize={fontSize} testID={`${staticString}-price-card`}>
      {price}
    </Text>
  </Stack>
);

const ViewDetails = () => {
  const { id = '' } = useParams();
  const pricingRequestId = id?.split('_')[0];
  const retailerId = id?.split('_')[1];
  const navigate = useNavigate();
  const { data: pricingListRequestData } = useQuery(
    [QueryKeys.GET_PRICING_REQUEST, pricingRequestId],
    () => PricingRequestsApi.getPricingRequest(pricingRequestId as string),
  );
  const { data: pricingOfferDetail } = useQuery(
    [QueryKeys.GET_PRICING_REQUEST_OFFER, pricingRequestId],
    () => PricingRequestsApi.getOffer(pricingRequestId),
  );

  const contactInformation = `${
    pricingListRequestData?.deliveryAddress1 ?? '-'
  } ${pricingListRequestData?.deliveryAddress2 ?? '-'}`;

  const getOfferData = pricingOfferDetail?.data?.find(
    (item: { pricingRequestRetailerId: string }) =>
      item.pricingRequestRetailerId === retailerId,
  );
  const subTotalPrice = getOfferData?.products?.reduce(
    (acc: number, current: { price: number }) => {
      return acc + current.price;
    },
    0,
  );

  const salespersonName = 
  getOfferData?.pricingRequestRetailer?.retailerSalesperson?.businessName?.length ? 
    getOfferData?.pricingRequestRetailer?.retailerSalesperson?.businessName 
    : getOfferData?.pricingRequestRetailer?.name ?? '-';

  const getPriceRequest = getOfferData?.publicId.split('-');
  return (
    <>
      <Container maxWidth='xl'>
        <VSpacer size='10' />
        <Button
          onClick={() => {
            navigate(
              `/admin/pricing-request/${pricingListRequestData?.id}`,
            );
          }}
          startIcon={<ArrowBackIcon />}
          testID='admin-pricing-request-back-button'
          variant='text'
        >
          {productRequestConstants.back}
        </Button>
        <Stack sx={{ width: '100%' }}>
          <VSpacer size='9' />
          <Text
            fontSize='28px'
            sx={{ lineHeight: '2rem' }}
          >{`${productRequestConstants.viewDetailHeading} ${salespersonName}`}</Text>
          <VSpacer size='9' />
          <Divider />
        </Stack>
        <Stack
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'revert',
          }}
        >
          <Box>
            <VSpacer size='10' />
            <Text
              category='overline'
              testID='card-text'
            >
              {productRequestConstants.priceRequest}
            </Text>
            <VSpacer size='5' />
            <Text fontSize='28px' testID='card-text'>
              {getPriceRequest && `#${getPriceRequest[0]}`}
            </Text>
            <VSpacer size='10' />
          </Box>
          <Box>
            <Text>{`${productRequestConstants.submittedOn} ${
              getOfferData?.createdAt &&
              DateTime.fromJSDate(getOfferData?.createdAt).toFormat('LLL dd, yyyy')
            }`}</Text>
          </Box>
        </Stack>
        <Stack
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'revert',
          }}
        >
          <Stack sx={{ display: 'flex', flexDirection: 'revert' }}>
            <Stack sx={{ width: '325px' }}>
              <VSpacer size='8' />
              <Text fontSize='28px' testID='card-text'>
                {productRequestConstants.details}
              </Text>
              <VSpacer size='7' />
              <Card
                sx={{
                  padding: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                {[
                  {
                    label: 'Product Sub Total',
                    value: `$${subTotalPrice?.toFixed(2)}`,
                  },
                  {
                    label: 'Shipment cost',
                    value: getOfferData?.totalShipmentCost
                      ? `+ $${getOfferData?.totalShipmentCost?.toFixed(2)}`
                      : `+ $0.00`,
                    fontSize: '12px',
                  },
                ].map(({ label, value, fontSize = '16px' }) => (
                  <>
                    <TotalCostComponent
                      fontSize={fontSize}
                      key={label}
                      price={value}
                      staticString={label}
                    />
                    <VSpacer size='3' />
                  </>
                ))}
                <Divider />
                <VSpacer size='3' />
                <TotalCostComponent
                  price={`$${getOfferData?.totalPrice?.toFixed(2)}`}
                  staticString={productRequestConstants.total}
                />
              </Card>
              <VSpacer size='5' />
              <Card sx={{ padding: '20px' }}>
                <Text testID='card-text'>
                  <span
                    style={{
                      marginRight: '5px',
                    }}
                  >
                    {`${productRequestConstants.expireOn}:`}
                  </span>
                  {pricingListRequestData?.expiration &&
                    DateTime.fromJSDate(pricingListRequestData.expiration)
                      .toFormat("LLL dd, yyyy 'at' hh:mm a")
                  }
                </Text>
                <VSpacer size='4' />
                <Text
                  sx={{ display: 'flex', alignItems: 'flex-start' }}
                  testID='card-text'
                >
                  <span
                    style={{
                      marginRight: '5px',
                    }}
                  >
                    {`${productRequestConstants.farmer}:`}
                  </span>
                  <span style={{ wordBreak: 'break-word' }}>
                    {pricingListRequestData?.user?.firstName !== null
                      ? `${pricingListRequestData?.user?.firstName}`
                      : ' _'}
                    {pricingListRequestData?.user?.lastName !== null
                      ? ` ${pricingListRequestData?.user?.lastName}`
                      : ' _'}
                    <Tooltip
                      title={` ${
                        pricingListRequestData?.user?.firstName !== null
                          ? pricingListRequestData?.user?.firstName
                          : ' _'
                      } 
                    ${
                      pricingListRequestData?.user?.lastName !== null
                        ? pricingListRequestData?.user?.lastName
                        : ' _'
                    }`}
                    >
                      <InfoOutlinedIcon
                        sx={{
                          fontSize: '16px',
                          marginLeft: '5px',
                          cursor: 'pointer',
                        }}
                      />
                    </Tooltip>
                  </span>
                </Text>
                <VSpacer size='4' />
                <Text sx={{ display: 'flex' }} testID='card-text'>
                  <span
                    style={{
                      marginRight: '5px',
                    }}
                  >
                    {`${productRequestConstants.farmName}:`}
                  </span>
                  <span
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      maxWidth: '190px',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {`${
                      pricingListRequestData?.user?.businessName !== null
                        ? pricingListRequestData?.user?.businessName
                        : '-'
                    }`}
                  </span>
                </Text>
                <VSpacer size='4' />
                <Text testID='card-text'>
                  <span
                    style={{
                      marginRight: '5px',
                    }}
                  >
                    {`${productRequestConstants.paymentType}:`}
                  </span>
                  {`${
                    pricingListRequestData?.paymentType !== null
                      ? pricingListRequestData?.paymentType
                      : '-'
                  }`}
                </Text>
                <VSpacer size='4' />
                <Text testID='card-text'>
                  <span
                    style={{
                      marginRight: '5px',
                    }}
                  >
                    {`${productRequestConstants.financing}:`}
                  </span>
                  test
                </Text>
                <VSpacer size='4' />
                <Text testID='card-text'>
                  <span
                    style={{
                      marginRight: '5px',
                    }}
                  >
                    {`${productRequestConstants.fulfillment}:`}
                  </span>
                  <span style={{ color: 'primary', cursor: 'pointer' }}>
                    {pricingListRequestData?.fulfillmentMethod}
                  </span>
                </Text>
                <VSpacer size='4' />
                <Text sx={{ display: 'flex' }} testID='card-text'>
                  <span
                    style={{
                      marginRight: '5px',
                    }}
                  >
                    {`${productRequestConstants.phoneNo}:`}
                  </span>
                  <span>
                    {pricingListRequestData?.user?.telephone !== null
                      ? `+1 ${pricingListRequestData?.user?.telephone}`
                      : '-'}
                  </span>
                </Text>
                <VSpacer size='4' />
                <Text sx={{ display: 'flex' }} testID='card-text'>
                  <span
                    style={{
                      marginRight: '5px',
                    }}
                  >
                    {`${productRequestConstants.email}:`}
                  </span>
                  <span
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {pricingListRequestData?.user?.email !== null
                      ? pricingListRequestData?.user?.email
                      : '-'}
                  </span>
                </Text>
                <VSpacer size='4' />
                <Text sx={{ display: 'flex' }} testID='card-text'>
                  <span
                    style={{
                      marginRight: '5px',
                    }}
                  >
                    {`${productRequestConstants.address}:`}
                  </span>
                  <span>{contactInformation}</span>
                </Text>
                <VSpacer size='4' />
              </Card>
            </Stack>
          </Stack>
          <HSpacer size='7' />
          <Stack sx={{ width: 'calc(100% - 325px)' }}>
            <VSpacer size='8' />
            <Text fontSize='28px' testID='card-text'>
              {`${productRequestConstants.product} (${getOfferData?.products?.length}) `}
            </Text>
            <VSpacer size='7' />
            {getOfferData?.products?.map((product) => {
              return (
                <React.Fragment key={product.id}>
                  <Card
                    key={product.id}
                    sx={{
                      padding: '20px',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Stack
                      key={product.id}
                      sx={{
                        padding: '20px 0',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'revert',
                        width: '100%',
                      }}
                    >
                      <Box>
                        <Text fontSize='20px' testID={`overline`}>
                          {product?.productDetail?.name}
                        </Text>
                        <VSpacer size='5' />
                        <Text category='overline' testID={`overline`}>
                          {product.quantity} {product.uom} {product.packageType ?? ''}
                        </Text>
                        <VSpacer size='3' />
                        {(product?.isCompanion ||
                          product?.isAlternate )&& (
                            <InlineMessage
                              icon={
                                <SwapHorizontalCircle
                                  color='info'
                                  fontSize='small'
                                />
                              }
                              testID={`product-card-substitute-product-${product?.id}`}
                              title={
                                product?.isCompanion ? 'Companion Product' : 'Alternate Product'
                              }
                              titleColor="primary"
                              titleSpacing='3'
                            />
                        )}
                        <Text sx={{ display: 'flex', alignItems: 'center' }}>
                          {product.substituteProduct &&
                          product.substituteProduct !== '' ? (
                            <>
                              <CompareArrowsIcon
                                sx={{
                                  backgroundColor: 'info.main',
                                  borderRadius: '100%',
                                  color: 'black',
                                  fontSize: '16px',
                                  marginRight: '5px',
                                }}
                              />
                              {product.substituteProduct}
                            </>
                            ) : (
                              ''
                            )}
                        </Text>
                      </Box>
                      <Box>
                        <Text fontSize='20px' testID={`overline`}>
                          {`$${product.price.toFixed(2)}`}
                        </Text>
                      </Box>
                    </Stack>
                  </Card>
                  <VSpacer size='5' />
                </React.Fragment>
              );
            })}
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default ViewDetails;
