import { Logo, VSpacer } from "@/components/DesignSystem";
import { ContactFooter } from "@/components/shared/ContactFooter";
import { DesktopOnly } from "@/components/shared/DesktopOnly";
import { MobileOnly } from "@/components/shared/MobileOnly";
import { Routes } from "@/constants/Routes";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import FarmerProfileForm from "@/pages/Auth/FarmerProfileForm";
import { OauthVerify } from '@/pages/Auth/OauthVerify';
import { PortalLogin } from '@/pages/Auth/PortalLogin';
import SetPasswordForm from "@/pages/Auth/SetPasswordForm";
import { VerifyEmail } from '@/pages/Auth/VerifyEmail';
import AcceptOfferSuccess from "@/pages/CompareAcceptOffers/AcceptOfferSuccess";
import ReviewSelectOffers from "@/pages/ReviewSelectOffers/ReviewSelectOffers";
import SavedRetailers from "@/pages/SavedRetailers/SavedRetailers";
import { ProductPage } from '@/pages/Shop/ProductPage';
import Shop from "@/pages/Shop/Shop";
import { getSubdomain } from '@/utilities/ThemeUtilities';
import { GlobalHeaderLayout } from '@/views/Layouts';
import { Container } from "@mui/material";
import React, { ReactNode } from 'react';
import { Navigate, Route, Routes as AppRoutes } from "react-router-dom";
import OrdersOverview from "@/pages/Admin/PricingRequestList/OrdersOverview";

export const LoggedInView = () => {
  const { windowHeight } = useMediaQuery();
  const subdomain = getSubdomain();

  const createPasswordLayout = (element: ReactNode) => {
    return (
      <>
        <MobileOnly>
          <Logo size="small" />
          <VSpacer size="4" />
          <Container maxWidth="xs">
            {element}
          </Container>
        </MobileOnly>
        <DesktopOnly>
          <Container maxWidth="xs" sx={{ textAlign: 'center' }}>
            <VSpacer size="12" />
            <Logo />
            <VSpacer size="13" />
            {element}
          </Container>
        </DesktopOnly>
        {subdomain === 'landus' ? (
          <ContactFooter />
        ) : (
          windowHeight > 770 ? (
            <ContactFooter fixedToBottom />
          ) : (
            <>
              <VSpacer size="8" />
              <ContactFooter />
              <VSpacer size="11" />
            </>
          )
        )}
      </>
    );
  };

  return (
    <AppRoutes>
      <Route element={<FarmerProfileForm />} path={Routes.MY_PROFILE} />
      <Route
        element={createPasswordLayout(<SetPasswordForm />)}
        path={Routes.CREATE_PASSWORD}
      />
      <Route
        element={<GlobalHeaderLayout><OrdersOverview/></GlobalHeaderLayout>}
        path={Routes.ADMIN_LIST_ORDERS}
      />
      <Route
        element={<GlobalHeaderLayout><ProductPage /></GlobalHeaderLayout>}
        path={Routes.PRODUCT_PAGE}
      />
      <Route
        element={<GlobalHeaderLayout><ReviewSelectOffers /></GlobalHeaderLayout>}
        path={Routes.REVIEW_SELECT_OFFERS}
      />
      <Route
        element={<GlobalHeaderLayout><SavedRetailers/></GlobalHeaderLayout>}
        path={Routes.SAVED_RETAILERS}
      />
      <Route
        element={<GlobalHeaderLayout><Shop /></GlobalHeaderLayout>}
        path={Routes.SHOP}
      />
      <Route element={<OauthVerify />} path={Routes.OAUTH_VERIFY} />
      <Route element={<PortalLogin />} path={Routes.PORTAL_LOGIN} />
      <Route
        element={<GlobalHeaderLayout><AcceptOfferSuccess/></GlobalHeaderLayout>}
        path={Routes.ACCEPT_OFFER_SUCCESS}
      />
      <Route
        element={<GlobalHeaderLayout><VerifyEmail /></GlobalHeaderLayout>}
        path={Routes.VERIFY_EMAIL}
      />
      <Route element={<Navigate to={Routes.SHOP} />} path={"*"}/>
    </AppRoutes>
  );
};
