import { Button, Dialog, Select, VSpacer } from '@/components/DesignSystem';
import { QueryKeys } from '@/constants/QueryKeys';
import { useGetRetailers } from '@/hooks/useHierarchyOfRetailers';
import { ManufacturerApi } from '@/utilities/api/ManufacturerApi';
import { UserApi } from '@/utilities/api/UserApi';
import { ApiManufacturer, ApiRetailer, ApiUser } from '@api/interfaces';
import { MenuItem } from '@mui/material';
import { uniqBy } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

type CreateWalletDialogProps = {
  farmer: ApiUser,
  onConfirm: (
    selectedRetailer: ApiRetailer,
    selectedManufacturer: ApiManufacturer | null,
  ) => void,
  show: boolean,
  setShow: Dispatch<SetStateAction<boolean>>,
}

const CreateWalletDialog = (
  {
    farmer,
    onConfirm,
    setShow,
    show,
  } : CreateWalletDialogProps,
) => {
  const [selectedRetailer, setSelectedRetailer] = useState<ApiRetailer>();
  const [selectedManufacturer, setSelectedManufacturer] = useState<ApiManufacturer | null>(null);
  const isAssociatedToRetailer = !!farmer.retailerId;
  const { data: locations, isLoading: isLocationsLoading } = useQuery(
    [QueryKeys.GET_USER_LOCATIONS],
    () => UserApi.getLocationsForUser(farmer.id),
    { enabled: !isAssociatedToRetailer },
  );

  const manufacturerFilter = selectedRetailer?.hasErpProducts
    ? { hasErpProducts: selectedRetailer.hasErpProducts, retailerId: selectedRetailer.id }
    : { countyId: farmer.countyId ?? undefined };
  const { data: manufacturers, isLoading: isManufacturersLoading } = useQuery(
    [QueryKeys.GET_MANUFACTURERS_LIST, selectedRetailer],
    () => ManufacturerApi.list(manufacturerFilter),
  );

  let retailers = locations?.map((location) => location.retailer!) ?? [];

  const farmerHasNoCounty = !farmer.countyId;
  const retailerIdFilter = farmer.retailerId ? [farmer.retailerId] : undefined;
  const { getRetailers } = useGetRetailers(
    { id: retailerIdFilter, isActive: true, limit: 1000 },
    false,
    farmerHasNoCounty || isAssociatedToRetailer,
  );

  useEffect(() => {
    if (isAssociatedToRetailer && getRetailers?.data?.length && !selectedRetailer) {
      setSelectedRetailer(getRetailers.data[0]);
    }
  }, [getRetailers, isAssociatedToRetailer, selectedRetailer]);

  if (isAssociatedToRetailer) {
    retailers = getRetailers?.data ?? [];
  } else if (farmerHasNoCounty) {
    retailers.push(...(getRetailers?.data ?? []));
  }

  const uniqueRetailers = uniqBy(retailers, 'id').sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Dialog
      acceptButton={() => (
        <Button
          disabled={isLocationsLoading || isManufacturersLoading || !selectedRetailer}
          loading={false}
          onClick={() => {
            if (selectedRetailer) {
              onConfirm(selectedRetailer, selectedManufacturer);
              setShow(false);
            }
          }}
          testID='save-rewards-button'
          variant='contained'
        >
          Save
        </Button>
      )}
      cancelButton={(props) => (
        <Button
          disabled={isLocationsLoading || isManufacturersLoading || false}
          onClick={() => setShow(false)}
          {...props}
          color='inherit'
          testID="rewards-cancel-button"
        >
          Cancel
        </Button>
      )}
      dialogWidth="500px"
      onClose={() => setShow(false)}
      open={show}
      showCloseButton={false}
      testID="add-rewards-dialog"
      title="Create Wallet"
    >
      <Select
        label="Retailer"
        renderValue={() => {
          return selectedRetailer?.name;
        }}
        required
        testID="add-reward-retailers-select"
        value={selectedRetailer}
      >
        {uniqueRetailers.map((retailer) => (
          <MenuItem
            key={retailer.id}
            onClick={() => {
              setSelectedRetailer(retailer);
            }}
            value={retailer.id}
          >
            {retailer.name}
          </MenuItem>
        ))}
      </Select>
      <VSpacer size="7" />
      <Select
        label="Manufacturer"
        onClear={() => setSelectedManufacturer(null)}
        renderValue={() => {
          return selectedManufacturer?.name;
        }}
        testID="add-reward-manufacturers-select"
        value={selectedManufacturer}
      >
        {manufacturers?.map((manufacturer) => (
          <MenuItem
            key={manufacturer.id}
            onClick={() => {
              setSelectedManufacturer(manufacturer);
            }}
            value={manufacturer.id}
          >
            {manufacturer.name}
          </MenuItem>
        ))}
      </Select>
    </Dialog>
  );
};

export default CreateWalletDialog;
