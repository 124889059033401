import { Button, HSpacer, Toolbar, VSpacer } from '@/components/DesignSystem';
import { Filter, FilterSelections } from '@/components/DesignSystem/Toolbar/interfaces';
import {
  AddAllProductsConfirmDialog,
} from '@/components/shared/ListSelector/AddAllProductsConfirmDialog';
import { Action } from '@/components/shared/ListSelector/helper';
import { ProductCard } from '@/components/shared/ListSelector/ProductCard';
import { QueryKeys } from '@/constants/QueryKeys';
import { useCategoryList, useGetRetailerList, useManufacturerList } from '@/hooks/useProductQuery';
import { useSearch } from '@/hooks/useSearch';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { ProductApi } from '@/utilities/api/ProductApi';
import { PromotionApi } from '@/utilities/api/PromotionApi';
import { ProductEndpoint } from '@api/endpoints';
import { ApiProduct, ApiPromotion, ApiRetailer } from '@api/interfaces';
import AddIcon from '@mui/icons-material/Add';
import { CircularProgress, Container, Divider, Pagination, Stack } from '@mui/material';
import { PromotionTargetUserType } from '@shared/enums/PromotionTargetUserType';
import { SharedConfig } from '@shared/SharedConfig';
import { formatDateOnly } from '@shared/utilities';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import ERPRetailerSelector from "@/components/shared/ListSelector/ERPRetailerSelector";

interface ProductListProps {
  onChange: (updatedIds: string[]) => void,
  selectedIds: string[],
}

export const ProductList = ({
  onChange,
  selectedIds,
}: ProductListProps) => {
  const [page, setPage] = useState(0);
  const { setSearch, debouncedSearch } = useSearch(3);
  const [filterSelections, setFilterSelections] =
    useState<FilterSelections | undefined>(() => new Map());
  const [selectedRetailer, setSelectedRetailer] = useState<ApiRetailer | undefined>();
  const [showAddAllProductsDialog, setShowAddAllProductsDialog] = useState(false);
  const { manufacturerList } = useManufacturerList({
    ...(selectedRetailer && { retailerId: selectedRetailer.id }),
    hasErpProducts: !!selectedRetailer,
  });
  const { categoryList } = useCategoryList({
    ...(selectedRetailer && { retailerId: selectedRetailer.id }),
  });
  const { retailerList } = useGetRetailerList({ hasErpProducts: false });
  const { openSnackbar } = useSnackbar();
  const [isAddingAll, setIsAddingAll] = useState(false);

  const selectedCategoryId = Array.from(filterSelections?.get('category-id') ?? []);
  const selectedManufacturerId = Array.from(filterSelections?.get('manufacturer-id') ?? []);
  const selectedRetailerId = Array.from(filterSelections?.get('retailer-id') ?? []);

  const sortDesc = filterSelections?.get('sortDesc')?.has('true');
  const query: ProductEndpoint.ProductList.Query = {
    categoryId: selectedCategoryId,
    hasExternalId: !!selectedRetailer,
    manufacturerId: selectedManufacturerId,
    page,
    retailerId: selectedRetailer ? [selectedRetailer.id] : selectedRetailerId,
    search: debouncedSearch,
    sortDesc,
  };

  const { data: products, isLoading } = useQuery(
    [QueryKeys.GET_PRODUCT, query],
    () => ProductApi.productListData(query),
  );

  const productIds = products?.data.map((product) => product.id);
  const { data: promotions } = useQuery(
    [QueryKeys.GET_PROMOTIONS, productIds],
    async () => PromotionApi.list({
      isActive: true,
      productIds: productIds,
    }),
    {
      enabled: !!productIds?.length,
    },
  );

  const currentDate = formatDateOnly(new Date());
  const getFarmerPromotions = (product: ApiProduct): ApiPromotion[] => {
    return promotions?.data.filter(
      (promotion) => (
        promotion.targetUserType === PromotionTargetUserType.Farmer
        && promotion.productIds.includes(product.id)
        && promotion.startDate <= currentDate && promotion.endDate >= currentDate
      ),
    ) ?? [];
  };

  const getRetailerPromotions = (product: ApiProduct): ApiPromotion[] => {
    return promotions?.data.filter(
      (promotion) => (
        promotion.targetUserType === PromotionTargetUserType.Retailer
        && promotion.productIds.includes(product.id)
        && promotion.startDate <= currentDate && promotion.endDate >= currentDate
      ),
    ) ?? [];
  };

  const categoryOptions = categoryList?.map((category) => ({
    id: category.id,
    label: category.name ?? '',
  })).sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  const manufacturerOptions = manufacturerList?.map((manufacturer) => ({
    id: manufacturer.id,
    label: manufacturer.name ?? manufacturer.id,
  })).sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  const retailerOptions = retailerList?.map((retailer) => ({
    id: retailer.id,
    label: retailer.name,
  })).sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  const addAll = async () => {
    if (!selectedRetailer) {
      return;
    }
    if ((products?.total ?? 0) > SharedConfig.maxPageLimit) {
      openSnackbar('Too many products. Please filter down the list.');
      return;
    }
    setIsAddingAll(true);
    const updatedIds = new Set(selectedIds);
    const { data: filteredProducts } = await ProductApi.productListData({
      ...query,
      limit: SharedConfig.maxPageLimit,
    });
    filteredProducts.forEach((product) => updatedIds.add(product.id));
    onChange(Array.from(updatedIds));
    setIsAddingAll(false);
  };

  const getFilters = (): Filter[] => {
    const filters: Filter[] = [
      {
        id: 'sortDesc',
        label: 'Sort By',
        options: [
          { id: 'false', label: 'A-Z', default: false },
          { id: 'true', label: 'Z-A', default: false },
        ],
        selectionMethod: 'single-select',
      },
      {
        id: 'category-id',
        label: 'Category',
        options: categoryOptions,
        selectionMethod: 'single-select',
      },
    ];
    if (retailerList?.length && !selectedRetailer) {
      filters.push({
        id: 'retailer-id',
        label: 'Retailer',
        options: retailerOptions,
        selectionMethod: 'single-select',
      });
    }
    filters.push({
      id: 'manufacturer-id',
      label: 'Manufacturer',
      options: manufacturerOptions,
      selectionMethod: 'single-select',
    });
    return filters;
  };

  const removeAll = async () => {
    if (selectedRetailer) {
      const newSelectedIds = new Set(selectedIds);
      retailerProductsIds.forEach((id) => newSelectedIds.delete(id));
      onChange(Array.from(newSelectedIds));
    } else {
      onChange([]);
    }
  };

  const retailerProductsIds = selectedRetailer?.productRetailers?.map(
    (retailerProduct) => retailerProduct.productId,
  ) ?? [];

  const showAddAll = !!selectedRetailer && !retailerProductsIds.every(
    (id) => selectedIds.includes(id),
  );
  const showRemoveAll = (!selectedRetailer && selectedIds.length)
    || (selectedRetailer && selectedIds.some((id) => retailerProductsIds.includes(id)));

  useEffect(() => {
    setPage(0);
  }, [debouncedSearch, filterSelections]);

  const onRetailerSelected = (retailer?: ApiRetailer) => {
    if (retailer?.id !== selectedRetailer?.id) {
      setFilterSelections(new Map());
      setSelectedRetailer(retailer);
    }
  };

  return (
    <Container maxWidth="lg">
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <ERPRetailerSelector
          defaultMenuItem={{
            label: 'Generic Products',
            logo: 'store',
          }}
          hasErpProducts={true}
          header='Products'
          onSelect={onRetailerSelected}
        />
        <Stack direction="row">
          {showRemoveAll && (
            <Button
              onClick={removeAll}
              testID="remove-all-products-button"
              variant="text"
            >
              Remove all
            </Button>
          )}
          {showAddAll && showRemoveAll && (
            <HSpacer size="5" />
          )}
          {showAddAll && (
            <Button
              loading={isAddingAll}
              onClick={() => setShowAddAllProductsDialog(true)}
              startIcon={<AddIcon />}
              testID="add-all-button"
            >
              Add all
            </Button>
          )}
        </Stack>
      </Stack>
      <VSpacer size="6" />
      <Divider />
      <VSpacer size="6" />
      <Toolbar
        filterSelections={filterSelections}
        filters={getFilters()}
        onChange={({ search, selections }) => {
          setSearch(search ?? '');
          setFilterSelections(selections);
        }}
        retainSelectionsOnFilterChange
        testID="product-list-toolbar"
        totalItems={products?.total ?? 0}
        totalUnit="product"
      />
      <VSpacer size="5" />
      {products?.data.map((product) => (
        <React.Fragment key={product.id}>
          <ProductCard
            farmerPromotions={getFarmerPromotions(product)}
            onAction={(action: Action) => {
              const updatedSelectedIds = new Set(selectedIds);
              if (action === Action.AddAll) {
                updatedSelectedIds.add(product.id);
              } else {
                updatedSelectedIds.delete(product.id);
              }
              onChange(Array.from(updatedSelectedIds));
            }}
            product={product}
            retailerPromotions={getRetailerPromotions(product)}
            selectedIds={selectedIds}
          />
          <VSpacer size="4" />
        </React.Fragment>
      ))}
      <VSpacer size='8' />
      {isLoading ? (
        <Stack
          alignItems='center'
          direction='column'
          justifyContent='center'
          sx={{ marginTop: '10rem' }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <>
          {products && !!products.lastPage && (
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='center'
            >
              <Pagination
                count={products.lastPage + 1}
                onChange={(_, page) => {
                  setPage(page - 1);
                }}
                page={products.page + 1}
              />
            </Stack>
          )}
        </>
      )}
      {showAddAllProductsDialog && (
        <AddAllProductsConfirmDialog
          onCancel={() => setShowAddAllProductsDialog(false)}
          onConfirm={async () => {
            setShowAddAllProductsDialog(false);
            await addAll();
          }}
          open
        />
      )}
    </Container>
  );
};
