import { AppConfig } from "@/constants/AppConfig";
import { PricingRequestEndpoint } from "@api/endpoints";
import { ApiPricingRequest } from '@api/interfaces';
import { SharedConfig } from '@shared/SharedConfig';
import { QueryUtil } from "../Query";
import { Client } from "./Client";

type LastLookUpdates = {
  lastLook: boolean
}

export class PricingRequestsApi {
  static async acceptOfferProducts (
    id: string,
    data: PricingRequestEndpoint.AcceptOfferProducts.Request,
  ) {
    await Client(`pricing-requests/${id}/accept`, { body: data });
  }

  static async addSalesPersonForAdmin (
    id: string,
    salesperson: PricingRequestEndpoint.Save.Salesperson,
  ): Promise<ApiPricingRequest> {
    return Client(`pricing-requests/${id}/salesperson`, { body: salesperson, method: 'POST' });
  }

  static async approvePricingRequest (id: string) {
    await Client(`pricing-requests/${id}/approve`, { method: "POST" });
  }

  static createPricingRequest (
    request: PricingRequestEndpoint.Create.Request,
  ): Promise<PricingRequestEndpoint.Create.Response> {
    return Client(`pricing-requests`, { body: request });
  }

  static async deleteDraftPriceRequest (id: string): Promise<void> {
    return Client(`pricing-requests/${id}`, { method: 'DELETE' });
  }

  static async deleteRetailerForAdmin (
    id: string,
    retailerId: string,
  ): Promise<ApiPricingRequest> {
    return Client(`pricing-requests/${id}/salesperson/${retailerId}`, { method: 'DELETE' });
  }

  static async endLastLook (id: string) {
    if (!AppConfig.env.test) {
      throw new Error("This method cannot be called in production");
    }
    await Client(`test/pricing-requests/${id}/end-last-look`, { method: "POST" });
  }

  static async expirePricingRequest (id: string) {
    if (!AppConfig.env.test) {
      throw new Error("This method cannot be called in production");
    }
    await Client(`test/pricing-requests/${id}/expire`, { method: "POST" });
  }

  static async expirePricingRequestReview (id: string) {
    if (!AppConfig.env.test) {
      throw new Error("This method cannot be called in production");
    }
    await Client(`test/pricing-requests/${id}/expireReview`, { method: "POST" });
  }

  static async exportOrderPayments (
    query: PricingRequestEndpoint.Export.Query,
  ): Promise<PricingRequestEndpoint.Export.Response> {
    const queryString = QueryUtil.stringify(query);
    return Client(`pricing-requests/export/order-payments?${queryString}`);
  }

  static async extendExpiration (
    id: string,
    updates: PricingRequestEndpoint.ExtendExpiration.Request,
  ) {
    await Client(`pricing-requests/${id}/extend-expiration`, { method: "POST", body: updates });
  }

  static getLastLook (
    pricingRequestId: string,
    token?: string,
  ): Promise<PricingRequestEndpoint.GetLastLook.Response> {
    const query = QueryUtil.stringify({ token });
    return Client(`pricing-requests/${pricingRequestId}/last-look?${query}`);
  }

  static getMostRecentDeliveryAddress (): Promise<
    PricingRequestEndpoint.GetMostRecentDeliveryAddress.Response
  > {
    return Client('pricing-requests/delivery-address');
  }

  static getOffers (pricingRequestId: string): Promise<PricingRequestEndpoint.ListOffers.Response> {
    const query = QueryUtil.stringify({ limit: SharedConfig.maxPageLimit });
    return Client(`pricing-requests/${pricingRequestId}/offers?${query}`);
  }

  static getPricingRequests (
    filters: PricingRequestEndpoint.List.Query,
  ): Promise<PricingRequestEndpoint.List.Response> {
    const query = QueryUtil.stringify(filters);
    return Client(`pricing-requests?${query}`);
  }

  static getPricingRequestsForAdmin (
    query: PricingRequestEndpoint.ListForAdmin.Query,
  ): Promise<PricingRequestEndpoint.ListForAdmin.Response> {
    return Client(`pricing-requests/admin?${QueryUtil.stringify(query)}`);
  }

  static getPricingRequestsForRetailerUser (
    query: PricingRequestEndpoint.ListForRetailer.Query,
  ): Promise<PricingRequestEndpoint.ListForRetailer.Response> {
    return Client(`pricing-requests/retailer?${QueryUtil.stringify(query)}`);
  }

  static getPricingRequest (
    id: string,
    nestProducts: boolean = true,
    includeDeleted: boolean = false,
    deliverableToken?: string,
  ): Promise<PricingRequestEndpoint.Get.Response> {
    const query = QueryUtil.stringify({ deliverableToken, includeDeleted, nestProducts });
    return Client(`pricing-requests/${id}?${query}`);
  }

  static getPricingRequestForDeliverable (
    id: string,
    deliverableToken: string,
  ): Promise<PricingRequestEndpoint.Get.Response> {
    const query = QueryUtil.stringify({ deliverableToken });
    return Client(`pricing-requests/${id}/deliverable?${query}`);
  }

  static getOffer (id: string): Promise<PricingRequestEndpoint.ListOffers.Response> {
    return Client(`pricing-requests/${id}/adminoffers`);
  }

  static getPricingRequestPublic (
    id: string,
    token?: string,
  ): Promise<PricingRequestEndpoint.GetPublic.Response> {
    const query = QueryUtil.stringify({ token });
    return Client(`pricing-requests/${id}/public?${query}`);
  }

  static async ignorePricingRequest (id: string) {
    await Client(`ignore-pricing-request/${id}`, { method: 'DELETE' });
  }

  static async rateAndClose (
    id: string,
    body: PricingRequestEndpoint.RateAndClose.Request,
  ) {
    await Client(`pricing-requests/${id}/rate-close-order`, { method: 'POST', body });
  }

  static async rejectPricingRequest (id: string) {
    await Client(`pricing-requests/${id}/reject`, { method: "POST" });
  }

  static async submit (id: string) {
    await Client(`pricing-requests/${id}/submit`, { method: "POST" });
  }

  static async updatePricingRequest (
    id: string,
    updates: PricingRequestEndpoint.Update.Request,
  ): Promise<ApiPricingRequest> {
    return Client(
      `pricing-requests/${id}`,
      { body: updates, method: 'PATCH' },
    );
  }
  static async updateLastLook (
    id: string,
    updates: LastLookUpdates,
  ): Promise<ApiPricingRequest> {
    return Client(
      `pricing-request-retailers/${id}`,
      { body: updates, method: 'PATCH' },
    );
  }
}
