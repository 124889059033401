import React, { useState } from 'react';
import { Table, TableRow } from '@/components/DesignSystem/Table/Table';
import { Button, Input, Modal, Text, VSpacer } from '@/components/DesignSystem';
import { ApiLoyaltySku } from '@api/interfaces/ApiLoyaltySku';
import { Stack, TableCell, TableRow as MuiTableRow } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '@/constants/QueryKeys';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { LoyaltySkuApi } from '@/utilities/api/LoyaltySkuApi';
import { LoyaltySkuEndpoint } from '@api/endpoints/LoyaltySkuEndpoint';
import Edit from '@mui/icons-material/Edit';

interface LoyaltySkuTableProps {
  manufacturerId?: string | null,
  retailerId: string,
}

export const LoyaltySkuTable = ({
  manufacturerId,
  retailerId,
}: LoyaltySkuTableProps) => {
  const { openSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [showLoyaltySkuModal, setShowLoyaltySkuModal] = useState(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const handleSave = async (productName: string, sku: string, id?: string) => {
    if (id) {
      await updateLoyaltySku({ id, body: { productName, sku } });
    } else {
      await createLoyaltySku({ manufacturerId, productName, retailerId, sku });
    }
  };

  const { data: loyaltySkus } = useQuery(
    [QueryKeys.GET_LOYALTY_SKUS, retailerId, manufacturerId],
    () => LoyaltySkuApi.list({ retailerId, manufacturerId }),
    {
      onError: () => {
        openSnackbar('Error fetching loyalty SKUs');
      },
    },
  );

  const selectedLoyaltySku = (selectedId && loyaltySkus)
    ? loyaltySkus.find((loyaltySku) => loyaltySku.id === selectedId)
    : null;

  const { mutate: createLoyaltySku } = useMutation(
    (body: LoyaltySkuEndpoint.Create.Request) => {
      return LoyaltySkuApi.create(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.GET_LOYALTY_SKUS);
        openSnackbar('Loyalty SKU created');
      },
      onError: (error: { message: string }) => {
        openSnackbar(
          error.message || 'Error creating loyalty SKU',
        );
      },
    },
  );

  const { mutate: updateLoyaltySku } = useMutation(
    async ({ id, body }: { id: string, body: LoyaltySkuEndpoint.Update.Request }) => {
      return LoyaltySkuApi.update(id, body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.GET_LOYALTY_SKUS);
        openSnackbar('Loyalty SKU updated');
      },
      onError: (error: { message: string }) => {
        openSnackbar(
          error.message || 'Error updating loyalty SKU',
        );
      },
    },
  );

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Text category="body-xlarge">
          ERP Loyalty SKU
        </Text>
        <Button
          disabled={!loyaltySkus}
          onClick={() => setShowLoyaltySkuModal(true)}
          startIcon={<AddIcon />}
          testID="add-loyalty-sku-button"
        >
          Add
        </Button>
      </Stack>
      <VSpacer size="6" />
      <Table
        headers={['Product Name', 'Product ERP ID (SKU)', '']}
      >
        {loyaltySkus?.length ? (
          !!loyaltySkus && loyaltySkus?.map((loyaltySku) => (
            <TableRow
              key={loyaltySku.id}
              values={[
                loyaltySku.product!.name,
                loyaltySku.product!.externalId,
                <Stack
                  alignItems="end"
                  key={`edit-loyalty-sku-${loyaltySku.id}`}
                >
                  <Button
                    color="primary"
                    onClick={() => {
                      setSelectedId(loyaltySku.id);
                      setShowLoyaltySkuModal(true);
                    }}
                    startIcon={<Edit />}
                    testID={`edit-loyalty-sku-${loyaltySku.id}`}
                    variant="text"
                  >
                    Edit
                  </Button>
                </Stack>,
              ]}
            />
          ))
        ) : (
          <MuiTableRow>
            <TableCell align="center" colSpan={3}>No loyalty SKUs have been created</TableCell>
          </MuiTableRow>
        )}
      </Table>
      {showLoyaltySkuModal && (
        <LoyaltySkuModal
          existingLoyaltySku={selectedLoyaltySku ?? undefined}
          onClose={() => {
            setShowLoyaltySkuModal(false);
            setSelectedId(null);
          }}
          onSave={handleSave}
          testID="loyalty-sku-modal"
        />
      )}
    </>
  );
};


interface LoyaltySkuModalProps {
  existingLoyaltySku?: ApiLoyaltySku,
  onClose: () => void,
  onSave: (name: string, sku: string, id?: string) => void,
  testID: string,
}

const LoyaltySkuModal = ({
  existingLoyaltySku,
  onClose,
  onSave,
  testID,
}: LoyaltySkuModalProps) => {
  const [name, setName] = useState(existingLoyaltySku?.product?.name ?? '');
  const [sku, setSku] = useState(existingLoyaltySku?.product?.externalId ?? '');

  const isEditing = !!existingLoyaltySku;
  const isChanged = !isEditing || (
    name !== existingLoyaltySku.product?.name
    || sku !== existingLoyaltySku.product?.externalId
  );
  const isValid = (
    !!name.length
    && !!sku.length
    && isChanged
  );

  return (
    <Modal
      acceptButton={(props) => (
        <Button
          {...props}
          disabled={!isValid}
          onClick={() => {
            onClose();
            onSave(name, sku, existingLoyaltySku?.id);
          }}
          testID={`${testID}-save-button`}
        >
          Save
        </Button>
      )}
      cancelButton={(props) => (
        <Button
          {...props}
          color="inherit"
          onClick={onClose}
          testID={`${testID}-cancel-button`}
        >
          Cancel
        </Button>
      )}
      onClose={onClose}
      open
      testID={testID}
      title={`${isEditing ? 'Edit' : 'Add'} ERP Loyalty SKU`}
    >
      <Input
        label="Product Name"
        onChangeText={setName}
        testID={`${testID}-name-input`}
        value={name}
      />
      <VSpacer size="6" />
      <Input
        label="Product ERP ID (SKU)"
        onChangeText={setSku}
        testID={`${testID}-sku-input`}
        value={sku}
      />
    </Modal>
  );
};
