import React, { Fragment, useEffect, useState } from 'react';
import { Button, ProgressLoader, Search, Text, VSpacer } from '@/components/DesignSystem';
import { Container, Divider, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useSearch } from '@/hooks/useSearch';
import { useQuery } from 'react-query';
import { QueryKeys } from '@/constants/QueryKeys';
import { ManufacturerApi } from '@/utilities/api/ManufacturerApi';
import ManufacturerListItem from './ManufacturerListItem';
import { ApiManufacturer, ApiRetailer } from '@api/interfaces';
import { SaveManufacturerModal } from './SaveEditManufacturer';
import { ManufacturerEndpoint } from "@api/endpoints";
import { useLocation, useNavigate } from "react-router-dom";
import ERPRetailerSelector from "@/components/shared/ListSelector/ERPRetailerSelector";

const ManufacturerList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const queryParamRetailerId = queryParams.get('retailerId');

  const [isAddManufacturerDialog, setIsAddManufacturerDialog] = useState(false);
  const { search, setSearch, debouncedSearch } = useSearch();

  const [selectedRetailer, setSelectedRetailer] = useState<ApiRetailer | undefined>();

  const query : ManufacturerEndpoint.List.Query = {
    hasExternalId: !!selectedRetailer,
    retailerId: selectedRetailer?.id,
    search: debouncedSearch,
  };

  const { data: manufacturersList = [] } = useQuery(
    [QueryKeys.GET_MANUFACTURERS, debouncedSearch, selectedRetailer?.id],
    () => ManufacturerApi.list(query),
  );

  const manufacturersLoading = (queryParamRetailerId ?? null) !== (selectedRetailer?.id ?? null);

  useEffect(() => {
    if (selectedRetailer !== undefined) {
      if (selectedRetailer?.id !== queryParamRetailerId) {
        const retailerId = selectedRetailer?.id;
        const urlParams: Record<string, string> = {};
        if (retailerId) {
          urlParams.retailerId = retailerId;
        }
        const params = new URLSearchParams(urlParams);
        navigate(`?${params.toString()}`);
      }
    } else {
      navigate('?');
    }
  }, [selectedRetailer]);

  const getManufacturerCountText = () => {
    if (selectedRetailer) {
      return manufacturersList.length === 1
        ? `1 ${selectedRetailer.name} manufacturer`
        : `${manufacturersList.length} ${selectedRetailer.name} manufacturers`;
    }
    return manufacturersList.length === 1
      ? '1 manufacturer (generic)'
      : `${manufacturersList.length} manufacturers (generic)`;
  };

  const onRetailerSelected = (retailer?: ApiRetailer) => {
    if (retailer === undefined || retailer?.id !== selectedRetailer?.id) {
      setSelectedRetailer(retailer);
    }
  };

  const LoadingSpinner = (
    <Stack alignItems="center">
      <ProgressLoader type="circular" />
    </Stack>
  );

  return (
    <Container maxWidth='lg'>
      <Stack>
        <VSpacer size='8' />
        <ERPRetailerSelector
          defaultMenuItem={{
            label: 'Generic',
            logo: 'manufacturer',
          }}
          hasErpProducts={true}
          header='Manufacturers'
          onSelect={onRetailerSelected}
          selectedId={queryParamRetailerId}
        />
        <VSpacer size='6' />
        <Divider />
        <VSpacer size='6' />
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
        >
          <Search
            onChangeText={setSearch}
            testID='counties-modal-search'
            value={search}
            width={289}
          />
          {!selectedRetailer && (
            <Button
              onClick={() => setIsAddManufacturerDialog(true)}
              size='medium'
              startIcon={<AddIcon />}
              testID='manage-partner-retailers-add-button'
            >
              Add Manufacturer
            </Button>
          )}
        </Stack>
        {manufacturersList?.length > 0 && (
          <>
            <VSpacer size='6' />
            <Text>{getManufacturerCountText()}</Text>
          </>
        )}
        <VSpacer size='5' />
        {manufacturersLoading && LoadingSpinner}
        {!manufacturersLoading && manufacturersList?.length === 0 && <Text>No results found</Text>}
        {!manufacturersLoading && manufacturersList?.map((manufacturer: ApiManufacturer) => (
          <Fragment key={manufacturer.id}>
            <ManufacturerListItem
              key={manufacturer.id}
              manufacturer={manufacturer}
              onClick={() => navigate(`/admin/manufacturers/${manufacturer.id}`)}
              showActions={false}
            />
            <VSpacer size='3' />
          </Fragment>
        ))}
        <VSpacer size='14' />
      </Stack>
      {isAddManufacturerDialog && (
        <SaveManufacturerModal
          close={() => setIsAddManufacturerDialog(false)}
        />
      )}
    </Container>
  );
};

export default ManufacturerList;
