import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import { HSpacer, Text } from '@/components/DesignSystem';
import { Stack } from '@mui/material';

const styles: SXStyles = {
  label: {
    color: '#C9C6BE',
    fontSize: '12px',
    fontFamily: 'roboto',
  },
  value: {
    color: '#fff',
    fontSize: '12px',
    fontFamily: 'roboto',
  },
};


interface ImageRequirementsHeaderProps {
  fileType?: string,
  maxFileSize?: string,
}

export const ImageRequirementsHeader = ({
  fileType = 'PNG, JPG, SVG',
  maxFileSize = '5 MB',
}: ImageRequirementsHeaderProps) => {
  return (
    <Stack direction="row" spacing="12px">
      {[
        ['File type', fileType],
        ['Max file size', maxFileSize],
      ].map(([label, value]) => (
        <Stack direction="row" key={label}>
          <Text sx={styles.label}>
            {label}:
          </Text>
          <HSpacer size="2" />
          <Text sx={styles.value}>
            {value}
          </Text>
        </Stack>
      ))}
    </Stack>
  );
};