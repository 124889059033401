import { AppConfig } from '@/constants/AppConfig';
import { LogoCategory } from '@/themes/variant-interfaces/LogoCategory';
import { LocalStorageUtility } from '@/utilities/LocalStorageUtility';

const LandusPortalProdUrl = 'https://portal.landus.ag/';
const LandusPortalDevStagingUrl = 'https://testportal.landus.ag/LandusCooperativeCustomerPortal/';

export const getFavicon = (subdomain?: string | null, forMobile = false) => {
  if (subdomain === 'landus') {
    return 'landus-favicon.png';
  }
  return forMobile ? 'apple-icon.png' : 'favicon.ico';
};

export const getLogo = (subdomain: string | null | undefined): LogoCategory => {
  switch (subdomain) {
    case 'landus':
      return 'landus-powered-by';
    default:
      return 'reverse-full';
  }
};

export const getPortalRedirectUrl = (subdomain: string) => {
  if (subdomain === 'landus') {
    return AppConfig.env.prod ? LandusPortalProdUrl : LandusPortalDevStagingUrl;
  }
  return `${window.location.origin}/#/error`;
};

export const navigateToStorefront = (subdomain: string | null, route?: string) => {
  const nextRoute = route ? `#${route}` : '';
  if (subdomain === null) {
    if (AppConfig.env.local) {
      LocalStorageUtility.remove('storefront');
    } else {
      const environment = AppConfig.env.devel ? 'devel' : 'staging';
      window.location.replace(`https://bidding.${environment}.growers.engineering/${nextRoute}`);
      return;
    }
  } else {
    if (AppConfig.env.local) {
      LocalStorageUtility.set('storefront', subdomain);
    } else {
      const environment = AppConfig.env.devel ? 'devel' : 'staging';
      window.location.replace(
        `https://${subdomain}.storefront.${environment}.growers.engineering/${nextRoute}`,
      );
      return;
    }
  }
  window.location.replace(`${nextRoute}`);
  window.location.reload();
};
