export enum DryFormulationUnitOfMeasure {
  POUND = 'lb',
  TON = 'ton',
  OUNCE = 'oz',
}

export enum LiquidFormulationUnitOfMeasure {
  FLUID_OUNCE = 'fl oz',
  PINT = 'pt',
  QUART = 'qt',
  GALLON = 'gal',
  TOTE = 'tote',
}

export enum SeedApplicationUnitOfMeasure {
  MSDS = 'msds',
  KSDS = 'ksds',
  LBS = 'lbs',
}

export enum SeedProductUnitOfMeasure {
  POUND = 'lb',
  BAG = 'bag',
  BUSHEL = 'bu',
}

export enum ServiceUnitOfMeasure {
  ACRE = 'ac',
}

export const ProductUomEnums = [
  DryFormulationUnitOfMeasure,
  LiquidFormulationUnitOfMeasure,
  SeedProductUnitOfMeasure,
  ServiceUnitOfMeasure,
];

export type ApplicationUom =
  SeedApplicationUnitOfMeasure
  | LiquidFormulationUnitOfMeasure
  | DryFormulationUnitOfMeasure;

export type ProductUom =
  SeedProductUnitOfMeasure
  | LiquidFormulationUnitOfMeasure
  | DryFormulationUnitOfMeasure
  | ServiceUnitOfMeasure;

export enum UomGroup {
  DRY = 'DRY',
  LIQUID = 'LIQUID',
  OTHER = 'OTHER',
}
