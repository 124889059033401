import defaultImage
  from '@/assets/logos/reverse/icon-product-generic_dry crop_nutrition_scaled.png';
import { Button, Modal, Text, VSpacer } from "@/components/DesignSystem";
import { AppConfig } from "@/constants/AppConfig";
import { viewProductConstant } from "@/constants/productConstant";
import { useActiveIngredients } from '@/hooks/useProductQuery';
import { useDeleteProductById } from "@/hooks/useProducts";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from "@mui/icons-material/Edit";
import { Box, Chip, Divider, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import DeleteConfirmation from "./DeleteConfirmation";
import { useQuery } from "react-query";
import { QueryKeys } from "@/constants/QueryKeys";
import { ProductApi } from "@/utilities/api/ProductApi";

interface ProductViewProps {
  disableActions?: boolean;
  isVisible: boolean;
  onClose: () => void;
  onEdit: (args: string) => void;
  productId?: string
}

const ViewProductModal = ({
  disableActions,
  isVisible,
  onClose,
  onEdit,
  productId,
}: ProductViewProps) => {
  const { data: product } = useQuery([QueryKeys.GET_PRODUCT, productId],
    () => ProductApi.getOneProductList(productId ?? '', true), {
      enabled: !!productId,
    });

  const SectionDivider = () => <>
    <VSpacer size="4" />
    <Divider />
    <VSpacer size="4" />
  </>;

  const Section = ({ label, value } : {
    label: string;
    value?: string | null;
  }) => <>
    <Text category="label-medium" sx={{ color: "#DBD8D2" }}>
      {label}
    </Text>
    <VSpacer size="1" />
    <Text category="body-medium">
      {value ? value : "N/A"}
    </Text>
  </>;

  const { deleteProductById } = useDeleteProductById();

  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog]=useState<boolean>(false);
  const [showSubcategoryA1, setShowSubcategoryA1]=useState<boolean>(false);
  const { activeIngredientsDataList } = useActiveIngredients();
  const formattedSubCategoryA1Data = product?.productSubcategories?.map((item) => {
    if (item.subcategories?.label === 2) {
      return item.subcategories.id;
    }
  }).filter(Boolean).join('');
  useEffect(() => {
    let shouldShowSubcategoryA1 = false;
    if(product && product.productCategory){
      if(product.productCategory.name === 'Seed'){
        if (product && product.productSubcategories) {
          for (const item of product.productSubcategories) {
            if (
              item.subcategories?.name !== 'Seed Treatment' &&
              item.subcategories?.name !== 'Biologicals'
            ) {
              shouldShowSubcategoryA1 = true;
              break;
            }
          }
        }
      }
    }

    setShowSubcategoryA1(shouldShowSubcategoryA1);
  }, [product]);

  return (
    <Modal
      cancelButton={(props) => (
        <Button onClick={onClose} {...props}>Close</Button>
      )}
      headerAccessoryRight={!disableActions &&
          <Button
              onClick={() => {
                onEdit(product?.id ?? '');
                onClose();
              }}
              startIcon={<EditIcon fontSize="small" />}
              testID="button-demo-enabled-outline-left-accessory"
              variant="text"
          >
            {viewProductConstant.edit}
          </Button>
      }
      onClose={()=>onClose()}
      open={isVisible}
      testID="view-product-modal"
      title={'View Product'}
      width={560}
    >
      <Box>
        <Text category="headline-small">
          {product?.name}
        </Text>
        <SectionDivider />
        <Section label="Manufacturer" value={product?.manufacturer?.name} />
        <SectionDivider />
        <Section label="Retailers" value={
          product?.productRetailers?.map((item) => (item?.retailerDetails?.name))?.join(', ')}
        />
        <SectionDivider />
        <Section label="Category filters" value={product?.productCategory?.name} />
        <SectionDivider />
        <Section label={viewProductConstant.SubcategoryA} value={
          product?.productSubcategories?.map((item) => (item.subcategories?.name)).join(', ')}
        />
        <SectionDivider />
        <Section label={viewProductConstant.primaryNutrient} value={
          product?.primaryNutrients?.map(({ name }) => (name)).join(', ')}
        />
        <SectionDivider />
        <Section label={viewProductConstant.activeIngredient} value={
          product?.activeIngredientsIds && activeIngredientsDataList
            .filter(({ id }: { id: string }) => (
              product?.activeIngredientsIds?.length &&
              product.activeIngredientsIds
                .find((ingredientsId) => ingredientsId === id)
            ))
            .map(({ name }: { name: string }) => name).join(', ')}
        />
        {showSubcategoryA1 && (
          <>
            <SectionDivider />
            <Section label={viewProductConstant.subcategoryA1} value={
              product?.productSubcategories?.map((item) => (
                item?.subcategories?.masterSubcategories
                  ?.filter((item: { id: string })=>
                    item.id === formattedSubCategoryA1Data,
                  ).map((itemA1) => itemA1.name)),
              ).join(', ')}
            />
            <SectionDivider />
          </>
        )}
        <SectionDivider />
        <Section label={viewProductConstant.cropType} value={
          product?.productCrops?.map((item) => item.crops?.name).join(', ')}
        />
        <SectionDivider />
        <Section label={viewProductConstant.practice} value={
          product?.productPractices?.map((item) => (item.practices?.name)).join(', ')}
        />
        <SectionDivider />
        <Section label={viewProductConstant.purchaseUoM} value={
          product?.purchaseUom?.join(', ')}
        />
        <SectionDivider />
        <Section label={viewProductConstant.packageSize} value={
          product?.packageSize?.join(', ')}
        />
        <SectionDivider />
        <Section label={viewProductConstant.sellSheet} value={product?.sellSheet} />
        <SectionDivider />
        <Section label={viewProductConstant.restrictedUse} value={
          product?.isRestrictedUse ? 'True' : 'False' }
        />
        <SectionDivider />
        <Stack paddingLeft="24px" spacing={3}>
          <Stack spacing={3}>
            <Text category="label-medium">{viewProductConstant.thumbnaillink}</Text>
            <Box sx={{ display:'flex', justifyContent:"center" }} >
              <img
                alt="image"
                src={product?.image
                  ? `${AppConfig.staticImageHost}/${product.image}`
                  : defaultImage}
                width="231px"
              />
            </Box>
          </Stack>
          <Divider />
          <Stack spacing={3}>
            <Text category="label-medium">{viewProductConstant?.Keywords}</Text>
            {product?.keywords && product?.keywords?.length > 0 && (
              <Box>
                {product.keywords.map((item: string, i: number) => {
                  return (
                    <Chip
                      key={i}
                      label={item}
                      sx={{
                        marginRight: "10px",
                        marginBottom: "10px",
                      }}
                      variant="outlined"
                    />
                  );
                })}
              </Box>
            )}
          </Stack>
          <Divider />
          <Stack spacing={3}>
            <Box
              sx={{
                border: "1px solid",
                borderRadius: "3px",
                display: "flex",
                padding: "20px",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Text fontSize="17px">{viewProductConstant.companionProducts}</Text>
                <VSpacer size="3" />
                {product?.companionProducts &&
                  product?.companionProducts?.map(
                    ({ id, productDetails }) => (
                      <Text fontSize="11px" key={id} multiline>
                        {productDetails?.name}
                      </Text>
                    ))}
              </Box>
            </Box>
          </Stack>
          <Stack spacing={3}>
            <Box
              sx={{
                border: "1px solid",
                borderRadius: "3px",
                display: "flex",
                padding: "20px",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Text fontSize="17px">{viewProductConstant.alternativeProducts}</Text>
                <VSpacer size="3" />
                {product?.alternativeProducts &&
                  product?.alternativeProducts?.map(
                    ({ id, productDetails }) => (
                      <Text fontSize="11px" key={id} multiline>
                        {productDetails?.name}
                      </Text>
                    ))}
              </Box>
            </Box>
          </Stack>
        </Stack>
        <VSpacer size="6" />
        {!disableActions && (
          <>
            <Divider />
            <VSpacer size="5" />
            <Stack alignContent='center'>
              <Button
                color="error"
                onClick={()=>{
                  setShowDeleteConfirmationDialog(true);
                }}
                startIcon={<DeleteOutlineIcon />}
                testID="button-demo-enabled-text"
                variant="text"
              >
                {viewProductConstant.deleteProduct}
              </Button>
            </Stack>
          </>
        )}
      </Box>
      {!disableActions && (<DeleteConfirmation
        handleDelete={() => deleteProductById(productId ?? '')}
        onClose={onClose}
        productFieldId={product?.id ?? ''}
        setShowDeleteConfirmationDialog={setShowDeleteConfirmationDialog}
        showDeleteConfirmationDialog={showDeleteConfirmationDialog}
      />)}
    </Modal>
  );
};

export default ViewProductModal;
